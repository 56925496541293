import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../components/ui/Modal'
import CustomAlert from '../components/ui/CustomAlert'
import { updateUser, fetchUserById } from '../store/slice/addUserSlice'
import backgroundImage from '../images/bgs.jpg'
import { MdEditDocument } from 'react-icons/md'

export default function UpdateUserAdmin({ userId, onUpdateSuccess }) {
    const [open, setOpen] = useState(false)
    const [govIdFile, setGovIdFile] = useState(null)
    const [govAddrFile, setGovAddrFile] = useState(null)
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        region: '',
        describesYou: '',
        termsAccepted: false,
        legalName: '',
        labelName: '',
        governmentId: '',
        governmentAddress: '',
        govtAddressAttach: null,
        governmentIDAttach: null,
        digitalSignature: null,
        mobileNumber: '',
        password: ''
    })
    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState({})
    const [digitalSignaturePreview, setDigitalSignaturePreview] = useState(null)
    const [govIdPreview, setGovIdPreview] = useState(null)
    const [govAddrPreview, setGovAddrPreview] = useState(null)

    const dispatch = useDispatch()
    const { loading, success, error, user } = useSelector((state) => state.user)

    useEffect(() => {
        if (open && userId) {
            dispatch(fetchUserById(userId))
        }
    }, [open, userId, dispatch])

    useEffect(() => {
        if (user) {
            setFormData({
                fullName: user.fullName || '',
                email: user.email || '',
                region: user.region || '',
                describesYou: user.describesYou || '',
                termsAccepted: user.termsAccepted || false,
                legalName: user.legalName || '',
                labelName: user.labelName || '',
                governmentId: user.governmentId || '',
                governmentAddress: user.governmentAddress || '',
                governmentIDAttach: null,
                govtAddressAttach: null,
                digitalSignature: null,
                mobileNumber: user.mobileNumber || '',
                password: user.password,
                bankName: user.bankName || '',
                accountNumber: user.accountNumber || '',
                ifscCode: user.ifscCode || '',
                accountHolderName: user.accountHolderName || ''
            })

            if (user.digitalSignature) {
                setDigitalSignaturePreview(
                    typeof user.digitalSignature === 'string'
                        ? user.digitalSignature
                        : URL.createObjectURL(user.digitalSignature)
                )
            }

            if (user.governmentIdAttachment) {
                setGovIdPreview(
                    typeof user.governmentIdAttachment === 'string'
                        ? user.governmentIdAttachment
                        : URL.createObjectURL(user.governmentIdAttachment)
                )
            }

            if (user.governmentAddressAttachment) {
                setGovAddrPreview(
                    typeof user.governmentAddressAttachment === 'string'
                        ? user.governmentAddressAttachment
                        : URL.createObjectURL(user.governmentAddressAttachment)
                )
            }
        }
    }, [user])

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target
        const inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value
        setFormData({
            ...formData,
            [name]: inputValue
        })

        if (type === 'file') {
            if (name === 'digitalSignature') {
                setDigitalSignaturePreview(URL.createObjectURL(files[0]))
            } else if (name === 'governmentIDAttach') {
                setGovIdFile(files[0])
                setGovIdPreview(URL.createObjectURL(files[0]))
            } else if (name === 'govtAddressAttach') {
                setGovAddrFile(files[0])
                setGovAddrPreview(URL.createObjectURL(files[0]))
            }
        }
    }

    const renderPreview = (file) => {
        if (!file) return null
        const fileType = file.type || file.split('.').pop()
        if (fileType.includes('image')) {
            return <img src={file} alt="Preview" className="h-32 w-32  object-cover mt-2" />
        } else if (fileType.includes('pdf')) {
            return <embed src={file} type="application/pdf" width="200" height="200" />
        }
    }

    const handleSubmit = () => {
        const data = new FormData()
        Object.keys(formData).forEach((key) => {
            if (
                formData[key] &&
                !(key === 'governmentIDAttach' || key === 'govtAddressAttach' || key === 'digitalSignature')
            ) {
                data.append(key, formData[key])
            }
        })
        if (govIdFile) data.append('governmentId', govIdFile)
        if (govAddrFile) data.append('governmentAddress', govAddrFile)
        if (formData.digitalSignature) data.append('digitalSignature', formData.digitalSignature)

        dispatch(updateUser({ userId, formDataPayload: data }))
            .unwrap()
            .then(() => {
                setShowAlert(true)
                setOpen(false)
                setAlertContent({
                    icon: 'success',
                    title: 'Success',
                    message: 'User updated successfully!'
                })

                onUpdateSuccess()
            })
            .catch(() => {
                setAlertContent({
                    icon: 'error',
                    title: 'Error',
                    message: 'Failed to update user.'
                })
                setShowAlert(true)
            })
    }

    const options = [
        { value: 'Artist', label: 'Artist' },
        { value: 'Producer', label: 'Producer' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Record Label', label: 'Record Label' },
        { value: 'Other', label: 'Other' }
    ]

    return (
        <main className="App">
            <div onClick={() => setOpen(true)}>
                <MdEditDocument className="text-blue-600 text-2xl cursor-pointer" />
            </div>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div
                    className="rounded-md border-green-500 p-4"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="modal-body w-[60vw] lg:w-[50vw] px-4 h-auto flex flex-col gap-2 overflow-y-auto max-h-[70vh] mt-6">
                        <div className="text-white grid grid-cols-2 gap-2">
                            <label className="flex flex-col gap-2">
                                Full Name
                                <input
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Full Name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                Email
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Email"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                Describe You
                                <select
                                    name="describesYou"
                                    value={formData.describesYou}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                >
                                    <option className="text-white bg-black" value="">
                                        Select Your Role
                                    </option>
                                    {options.map((option) => (
                                        <option className="text-white bg-black" key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label className="flex flex-col gap-2">
                                Legal Name
                                <input
                                    type="text"
                                    name="legalName"
                                    value={formData.legalName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Legal Name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                Label Name
                                <input
                                    type="text"
                                    name="labelName"
                                    value={formData.labelName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Label Name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                Government ID Name
                                <select
                                    name="governmentId"
                                    value={formData.governmentId}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                >
                                    <option className="text-white bg-black" value="">
                                        Select ID Type
                                    </option>
                                    <option className="text-white bg-black" value="Aadhar Card">
                                        Aadhar Card
                                    </option>
                                    <option className="text-white bg-black" value="Voter Card">
                                        Voter Card
                                    </option>
                                    <option className="text-white bg-black" value="Driving License">
                                        Driving License
                                    </option>
                                    <option className="text-white bg-black" value="Pan">
                                        Pan
                                    </option>
                                    <option className="text-white bg-black" value="Citizen Identity">
                                        Citizen Identity
                                    </option>
                                    <option className="text-white bg-black" value="Others">
                                        Others
                                    </option>
                                </select>
                            </label>
                            <label className="flex flex-col gap-2">
                                Government ID
                                <input type="file" name="governmentIDAttach" onChange={handleInputChange} />
                                {govIdPreview && renderPreview(govIdPreview)}
                            </label>
                            <label className="flex flex-col gap-2">
                                Government Address Name
                                <select
                                    name="governmentAddress"
                                    value={formData.governmentAddress}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                >
                                    <option className="text-white bg-black" value="">
                                        Select Address Type
                                    </option>
                                    <option className="text-white bg-black" value="Aadhar Card">
                                        Aadhar Card
                                    </option>
                                    <option className="text-white bg-black" value="Voter Card">
                                        Voter Card
                                    </option>
                                    <option className="text-white bg-black" value="Driving License">
                                        Driving License
                                    </option>
                                    <option className="text-white bg-black" value="Pan">
                                        Pan
                                    </option>
                                    <option className="text-white bg-black" value="Citizen Identity">
                                        Citizen Identity
                                    </option>
                                    <option className="text-white bg-black" value="Others">
                                        Others
                                    </option>
                                </select>
                            </label>
                            <label className="flex flex-col gap-2">
                                Government Address
                                <input type="file" name="govtAddressAttach" onChange={handleInputChange} />
                                {govAddrPreview && renderPreview(govAddrPreview)}
                            </label>
                            <label className="flex flex-col gap-2 ">
                                <span className='text-red-500'> Digital Signature (.png)</span>
                                <input type="file" accept=".png" name="digitalSignature" onChange={handleInputChange} />
                                {digitalSignaturePreview && renderPreview(digitalSignaturePreview)}
                            </label>
                            <label className="flex flex-col gap-2">
                                Mobile Number
                                <input
                                    type="tel"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Mobile Number"
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                Ac. Holder Name
                                <input
                                    type="text"
                                    name="accountHolderName"
                                    value={formData.accountHolderName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Holder name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                Bank Name
                                <input
                                    type="text"
                                    name="bankName"
                                    value={formData.bankName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Bank name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                Ac. Number
                                <input
                                    type="text"
                                    name="accountNumber"
                                    value={formData.accountNumber}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Ac. number"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                IFSC/SWIFT Code
                                <input
                                    type="text"
                                    name="ifscCode"
                                    value={formData.ifscCode}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="IFSC"
                                />
                            </label>
                            {/* <label className="flex flex-col gap-2">
                                Password
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Password"
                                />
                            </label> */}
                            <label className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    name="termsAccepted"
                                    checked={formData.termsAccepted}
                                    onChange={handleInputChange}
                                    className="form-checkbox text-green-500"
                                />
                                <span>Accept Terms & Conditions</span>
                            </label>
                        </div>
                        <button
                            className="bg-green-500 w-24 text-white p-2 rounded-md mt-4"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Updating...' : 'Update User'}
                        </button>
                    </div>
                </div>
            </Modal>
            <CustomAlert
                isOpen={showAlert}
                icon={alertContent.icon}
                title={alertContent.title}
                message={alertContent.message}
                onClose={() => setShowAlert(false)}
            />
        </main>
    )
}
