import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchCreateChannelEntries = createAsyncThunk(
  'createChannel/fetchCreateChannelEntries',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/getAllNewCreateLinkCreateChannel`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token'))
      }
    });
    return response.data.data;
  }
);


export const approveCreateChannelEntry = createAsyncThunk(
  'createChannel/approveCreateChannelEntry',
  async (claimId) => {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/approveLinkCreateChannel/${claimId}`, {}, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token'))
      }
    });
    return response.data; 
  }
);


export const rejectCreateChannelEntry = createAsyncThunk(
  'createChannel/rejectCreateChannelEntry',
  async (claimId) => {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/rejectLinkCreateChannel/${claimId}`, {}, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token'))
      }
    });
    return response.data; 
  }
);


const createChannelSlice = createSlice({
  name: 'createChannel',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateChannelEntries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreateChannelEntries.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchCreateChannelEntries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(approveCreateChannelEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveCreateChannelEntry.fulfilled, (state, action) => {
        // Update the state after approval
        state.loading = false;
        // You may update the state data as per your requirement after approval
      })
      .addCase(approveCreateChannelEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(rejectCreateChannelEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectCreateChannelEntry.fulfilled, (state, action) => {
        // Update the state after rejection
        state.loading = false;
        // You may update the state data as per your requirement after rejection
      })
      .addCase(rejectCreateChannelEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default createChannelSlice.reducer;
