import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch all artist channel verifications
export const fetchArtistChannelVerifications = createAsyncThunk('artistChannelVerification/fetchArtistChannelVerifications', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/ArtistChannelVerificationByadmin`, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))
    }
  });
  return response.data.data;
});

// Async thunk to approve an artist channel verification
export const approveArtistChannelVerification = createAsyncThunk('artistChannelVerification/approveArtistChannelVerification', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/ArtistChannelVerification/approve/${id}`, {}, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))
    }
  });
  return response.data;
});

// Async thunk to reject an artist channel verification
export const rejectArtistChannelVerification = createAsyncThunk('artistChannelVerification/rejectArtistChannelVerification', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/ArtistChannelVerification/reject/${id}`, {}, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))
    }
  });
  return response.data;
});

const artistChannelVerificationSlice = createSlice({
  name: 'artistChannelVerification',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArtistChannelVerifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchArtistChannelVerifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchArtistChannelVerifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(approveArtistChannelVerification.fulfilled, (state, action) => {
        const index = state.data.findIndex(item => item._id === action.meta.arg);
        if (index !== -1) {
          state.data[index].status = 'Approved';
        }
      })
      .addCase(rejectArtistChannelVerification.fulfilled, (state, action) => {
        const index = state.data.findIndex(item => item._id === action.meta.arg);
        if (index !== -1) {
          state.data[index].status = 'Rejected';
        }
      });
  },
});

export default artistChannelVerificationSlice.reducer;
