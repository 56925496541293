import React from 'react';

const Selection = ({ options, selectedOption, onSelect }) => {
    return (
        <select
            value={selectedOption}
            onChange={(e) => onSelect(e.target.value)}
            className="border text-white border-white p-1 w-[5rem] bg-black"
        >
            <option value="">Select an option</option>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Selection;
