import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchVisualReport = createAsyncThunk(
  'export/fetchVisualReport',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/export/ReportAndPaymentVisualClaims`,
        {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('token'))
            }
        }
    );
    return response.data;
  }
);


export const fetchMCNReport = createAsyncThunk(
  'export/fetchMCNReport',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/export/mcnChannels`,
        {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('token'))
            }
        }
    );
    return response.data;
  }
);

const exportSlice = createSlice({
  name: 'export',
  initialState: {
    visualReport: null,
    mcnReport: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVisualReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVisualReport.fulfilled, (state, action) => {
        state.loading = false;
        state.visualReport = action.payload;
      })
      .addCase(fetchVisualReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMCNReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMCNReport.fulfilled, (state, action) => {
        state.loading = false;
        state.mcnReport = action.payload;
      })
      .addCase(fetchMCNReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default exportSlice.reducer;
