import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/shared/Layout'

import Dashboard from './pages/Dashboard'

import MusicDistribuotion from './pages/MusicDistribuotion'
import Claims from './pages/Claims'
import Connects from './pages/Connects'
import ReportsPayments from './pages/ReportsPayments'
import Termservice from './pages/Termservice'
import HelpSupport from './pages/HelpSupport'
import AdminLogin from './pages/AdminLogin'
import Clients from './pages/Clients'
import ReportPaymentMCN from './pages/ReportPaymentMCN'
import AllUsers from './pages/AllUsers'
import ArtistChannel from './pages/ArtistChannel'
import VisualClaimRelease from './pages/VisualClaimRelease'
import Whitelist from './pages/Whitelist'
import { Toaster } from 'react-hot-toast'
import SyndicateRequest from './pages/SyndicateRequest'
import ProtectedRoute from './private/ProtectedRoute'
import { AuthProvider } from './context/AuthContext'

function App() {
    return (
        <AuthProvider>
            <Router>
                <Toaster />
                <Routes>
                    <Route path="/" element={<AdminLogin />} />

                    <Route
                        path="/dashboard/*"
                        element={
                            <ProtectedRoute>
                                <Layout />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<Dashboard />} />
                        <Route path="music" element={<MusicDistribuotion />} />
                        <Route path="claims" element={<Claims />} />
                        <Route path="VisualClaimsRelease" element={<VisualClaimRelease />} />
                        <Route path="connects" element={<Connects />} />
                        <Route path="reports" element={<ReportsPayments />} />
                        <Route path="terms" element={<Termservice />} />
                        <Route path="helps" element={<HelpSupport />} />
                        <Route path="clients" element={<Clients />} />
                        <Route path="users" element={<AllUsers />} />

                        <Route path="rpMCN" element={<ReportPaymentMCN />} />

                        <Route path="artistChannel" element={<ArtistChannel />} />
                        <Route path="whitelist" element={<Whitelist />} />
                        <Route path="syndicateRequest" element={<SyndicateRequest />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    )
}

export default App
