import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Fetch all syndicate authorizations
export const fetchSyndicateAuthorizations = createAsyncThunk(
    'syndicate/fetchSyndicateAuthorizations',
    async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/all/syndication/authorizations`, {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('token'))
            }
        });

        return response.data.data;
    }
);

// Search syndicate authorizations
export const searchSyndicateAuthorizations = createAsyncThunk(
    'syndicate/searchSyndicateAuthorizations',
    async (searchValue) => {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/api/search/syndication/authorizations?searchValue=${searchValue}`,
            {},
            {
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('token'))
                }
            }
        );

        return response.data.data;
    }
);

// Search users
export const searchUsers = createAsyncThunk(
    'syndicate/searchUsers',
    async (searchValue) => {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/api/search/users?searchValue=${searchValue}`,
            {},
            {
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('token'))
                }
            }
        );

        return response.data.data.allUserdata;
    }
);

// Export approved users to Excel
export const exportApprovedUsers = createAsyncThunk(
    'syndicate/exportApprovedUsers',
    async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/exportData`, {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('token'))
            }
        });

        return response.data.url;
    }
);

const syndicateSlice = createSlice({
    name: 'syndicate',
    initialState: {
        syndicateAuthorizations: [],
        users: [],
        searchResults: [], 
        exportUrl: '',
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSyndicateAuthorizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSyndicateAuthorizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.syndicateAuthorizations = action.payload;
            })
            .addCase(fetchSyndicateAuthorizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(searchSyndicateAuthorizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(searchSyndicateAuthorizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.syndicateAuthorizations = action.payload;
            })
            .addCase(searchSyndicateAuthorizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(searchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.searchResults = action.payload; 
            })
            .addCase(searchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(exportApprovedUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(exportApprovedUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.exportUrl = action.payload;
            })
            .addCase(exportApprovedUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default syndicateSlice.reducer;
