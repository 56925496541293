import { X } from 'react-feather';
import PropTypes from 'prop-types';

export default function Modal({ open, onClose, children }) {
    return (
        <div
            onClick={onClose}
            className={`
                fixed inset-0  z-50  flex justify-center items-center transition-opacity duration-300
                ${open ? 'visible bg-black/50 opacity-100' : 'invisible opacity-0'}
            `}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={`
                    bg-white rounded-2xl shadow-lg transition-transform duration-300 transform
                    ${open ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}
                `}
            >
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
                >
                    <X />
                </button>
                {children}
            </div>
        </div>
    );
}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};
