import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineLogout } from 'react-icons/hi'
import { Menu, X } from 'react-feather'
import Dropdown from '../ui/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../store/slice/authSlices'
import { useAuth } from '../../context/AuthContext'
import logo from '../../images/CRM LOGO.png'
import sidebarBg from '../../images/finalbg1.png'
import Dash from '../../images/1.png'
import MusicIcon from '../../images/4.png'
import VisualClaimIcon from '../../images/2.png'
import MCNConnectIcon from '../../images/connect.png'
import ReportsIcon from '../../images/5.png'
import ClientsIcon from '../../images/6.png'
import termsIcon from '../../images/7.png'
import power from '../../images/POWER LOGOUT.png'

const linkClass =
    'flex rounded-lg bg-zinc-700  opacity-75 items-center gap-2  px-1 py-1    hover:bg-blue-300 hover:no-underline active:bg-blue-500  text-white'

const collapsedLinkClass =
    'flex items-center gap-2 font-medium px-1 py-1 hover:bg-blue-300 hover:no-underline active:bg-blue-500 rounded-sm text-xl text-white/0'

const typographyClass = 'font-poppins text-[10px] lg:text-sm  leading-8 tracking-wide text-left'

export default function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { logoutAdm } = useAuth()
    const auth = useSelector((state) => state.auth)

    const handleLogout = () => {
        dispatch(logout())
        logoutAdm()
        navigate('/')
    }

    const isActiveLink = (link) => location.pathname === link

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/')
        }
    }, [navigate, auth.user])

    return (
        <div
            className={classNames('p-3 mt-2 flex flex-col  relative min-h-screen', {
                'w-62': !isCollapsed,
                'w-16': isCollapsed
            })}
        >
            <div
                 className="absolute h-full mb-4 inset-0 bg-cover bg-center rounded-e-[50px] border border-red-500" 
                style={{ backgroundImage: `url(${sidebarBg})` }}
            ></div>
            <div className="relative mb-20   z-10 flex flex-col justify-between h-full">
                {/* Top Section */}
                <div className="flex flex-col">
                    <div className="flex items-center flex-col pt-4  gap-2 flex-wrap px-1 py-3">
                        <button className="pt-0" onClick={() => setIsCollapsed(!isCollapsed)}>
                            {isCollapsed ? (
                                <Menu className="w-6 h-6 text-neutral-100" />
                            ) : (
                                <X className="w-6 h-6 text-neutral-100" />
                            )}
                        </button>
                        {!isCollapsed ? (
                            <>
                                <img src={logo} className="w-24 h-24 " alt="logo" />
                            </>
                        ) : null}
                    </div>

                    <div
                        className={classNames('py-8 flex flex-1 flex-col gap-1', {
                            'overflow-hidden': isCollapsed,
                            'h-48': isCollapsed
                        })}
                    >
                        <Link
                            to="/dashboard"
                            className={
                                !isCollapsed
                                    ? `${classNames(linkClass, {
                                          'bg-blue-500 ': isActiveLink('/dashboard')
                                      })} ${typographyClass}`
                                    : `${collapsedLinkClass} ${typographyClass}`
                            }
                        >
                            <img src={Dash} className="w-6 h-6" alt="Dashboard" />
                            {!isCollapsed && 
                            <span style={{ letterSpacing: '0.2em' }}>DASHBOARD</span>
                            }
                        </Link>

                        <Dropdown
                            imageSrc={MusicIcon}
                            label="Music Distribution"
                            links={[
                                { label: 'Claim Release Request', href: '/dashboard/music' },
                                { label: 'OAC Request', href: '/dashboard/artistChannel' }
                            ]}
                            isCollapsed={isCollapsed}
                            isActiveParent={isActiveLink('/dashboard/music')}
                        />

                        <Dropdown
                            imageSrc={VisualClaimIcon}
                            label="Visual Claim"
                            links={[
                                { label: 'Start Claim Request', href: '/dashboard/claims' },
                                { label: 'Claim Release Request', href: '/dashboard/VisualClaimsRelease' },
                                { label: 'Whitelist Request', href: '/dashboard/whitelist' },
                                { label: 'Syndicate Request', href: '/dashboard/syndicateRequest' }
                            ]}
                            isCollapsed={isCollapsed}
                            isActiveParent={
                                isActiveLink('/dashboard/claims') ||
                                isActiveLink('/dashboard/VisualClaimsRelease') ||
                                isActiveLink('/dashboard/whitelist')
                            }
                        />

                        <Link
                            to="/dashboard/connects"
                            className={
                                !isCollapsed
                                    ? `${classNames(linkClass, {
                                          'bg-blue-500': isActiveLink('/dashboard/connects')
                                      })} ${typographyClass}`
                                    : `${collapsedLinkClass} ${typographyClass}`
                            }
                        >
                            <img src={MCNConnectIcon} className="w-6 h-6" alt="MCN Connects" />
                            {!isCollapsed && <span>MCN Connect</span>}
                        </Link>

                        <Dropdown
                            imageSrc={ReportsIcon}
                            label="Reports & Payment"
                            links={[
                                { label: 'Visual Claims Report', href: '/dashboard/reports' },
                                { label: 'MCN Channels Report', href: '/dashboard/rpMCN' }
                            ]}
                            isCollapsed={isCollapsed}
                            isActiveParent={isActiveLink('/dashboard/reports') || isActiveLink('/dashboard/rpMCN')}
                        />

                        <Dropdown
                            imageSrc={ClientsIcon}
                            label="All Clients"
                            links={[
                                { label: 'Requested User', href: '/dashboard/users' },
                                { label: 'Approved User', href: '/dashboard/clients' }
                            ]}
                            isCollapsed={isCollapsed}
                            isActiveParent={isActiveLink('/dashboard/users') || isActiveLink('/dashboard/clients')}
                        />
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="flex flex-col  justify-center items-center gap-0.5 pt-2  ">
                    <Link
                        to="/dashboard/terms"
                        className={
                            !isCollapsed
                                ? `${classNames(linkClass, {
                                      'bg-blue-500': isActiveLink('/dashboard')
                                  })} ${typographyClass}`
                                : `${collapsedLinkClass} ${typographyClass}`
                        }
                    >
                        <img src={termsIcon} className="w-6 h-6" alt="Dashboard" />
                        {!isCollapsed && <span>Terms of Service</span>}
                    </Link>
                    <button className="flex flex-col items-center justify-center mb-4" onClick={handleLogout}>
                        <img src={power} className="w-6 h-6" alt="Logout" />
                        <p className="text-white text-[10px] ">LOGOUT</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

