import React from 'react';

const Preloader = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-white h-screen">
        <video
            autoPlay
            loop
            muted
            playsInline
            width="100%"
            height="100%"
            className="w-screen h-screen object-cover"
        >
            <source src="https://brandroutemedia.s3.ap-south-1.amazonaws.com/BrandRoute-internal/logovideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
);

export default Preloader;
