import React from 'react';
import { FaFilePdf, FaLink } from 'react-icons/fa';

const Documents = ({ user }) => {
    return (
        <details className="dropdown">
            <summary className="m-1 ">Documents</summary>
            <ul className="p-2 shadow menu dropdown-content  bg-black text-white z-[4] bg-base-100 rounded-box w-64 absolute">
                <li>
                    <a href={user.pdfUrl} target="_blank" rel="noopener noreferrer" className="text-white  hover:no-underline flex items-center">
                        <FaFilePdf className="mr-2" /> TOS Agreement
                    </a>
                </li>
            
                <li>
                    <a href={user.governmentIdAttachmentFilePath} target="_blank" rel="noopener noreferrer" className="text-white hover:no-underline flex items-center">
                        <FaFilePdf className="mr-2" /> Government ID
                    </a>
                </li>
                <li>
                    <a href={user.governmentAddressAttachmentFilePath} target="_blank" rel="noopener noreferrer" className="text-white  flex hover:no-underline items-center">
                        <FaFilePdf className="mr-2" /> Government Address
                    </a>
                </li>
            </ul>
        </details>
    );
};

export default Documents;
