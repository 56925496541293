import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSyndicateAuthorizations, searchSyndicateAuthorizations } from '../store/slice/syndicateSlice';
import Loader from '../components/ui/Loader';
import { IoEye } from 'react-icons/io5';
import { GrPowerReset } from 'react-icons/gr';
import { FaSearch } from 'react-icons/fa';
import Pagination from '../components/shared/Pagination'
const userImage = require('../images/download.jpeg');
const backgroundImage= require ('../images/Backround.jpg')

const SyndicateRequest = () => {
    const dispatch = useDispatch();
    const { syndicateAuthorizations, status, error } = useSelector((state) => state.syndicate);

    useEffect(() => {
        dispatch(fetchSyndicateAuthorizations());
    }, [dispatch]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const itemsPerPage = 20;

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchValue.trim() !== '') {
            dispatch(searchSyndicateAuthorizations(searchValue));
        } else {
            dispatch(fetchSyndicateAuthorizations());
        }
        setCurrentPage(1);
    };

    const handleReset = () => {
        setSearchValue('');
        dispatch(fetchSyndicateAuthorizations());
        setCurrentPage(1);
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = syndicateAuthorizations.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(syndicateAuthorizations.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <li key={i}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === i ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(i)}
                        >
                            {i}
                        </button>
                    </li>
                );
            }
        } else {
            const startPage = Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, currentPage + 2);

            if (startPage > 1) {
                pageNumbers.push(
                    <li key={1}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === 1 ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(1)}
                        >
                            1
                        </button>
                    </li>
                );

                if (startPage > 2) {
                    pageNumbers.push(
                        <li key="ellipsis-start">
                            <span className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">
                                .....
                            </span>
                        </li>
                    );
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <li key={i}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === i ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(i)}
                        >
                            {i}
                        </button>
                    </li>
                );
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    pageNumbers.push(
                        <li key="ellipsis-end">
                            <span className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">
                                .....
                            </span>
                        </li>
                    );
                }

                pageNumbers.push(
                    <li key={totalPages}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === totalPages ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(totalPages)}
                        >
                            {totalPages}
                        </button>
                    </li>
                );
            }
        }

        return pageNumbers;
    };

    return (
        <div className="w-full h-screen bg-cover bg-center p-3 flex flex-col gap-4 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`,}}
        >
            <div className="flex items-center justify-between flex-wrap">
                <div className="flex flex-col gap-2">
                    <h3 className="text-xl font-bold">Syndicate Request</h3>
                    <p className="text-md text-gray-500 mb-4">Keep Track of Syndicate Request and Status.....</p>
                </div>
                <form className="flex gap-2" onSubmit={handleSearch}>
                    <input
                        className="w-[15rem] border border-black rounded-xl bg-transparent px-4 py-2"
                        type="search"
                        placeholder="Search name..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button type="submit" className="bg-blue-500 p-2 rounded-lg">
                        <FaSearch className="text-xl text-white" />
                    </button>

                    <button type="button" className="bg-red-500 p-2 rounded-lg" onClick={handleReset}>
                        <GrPowerReset className="h-5 w-5 text-white" />
                    </button>
                </form>
            </div>

            <table className="bg-white shadow-lg">
                <thead className='bg-black text-white '>
                    <tr>
                        <th className="px-4 py-2 text-left">Username</th>
                        <th className="px-4 py-2 text-left">Channel Name</th>
                        <th className="px-4 py-2 text-center">Visual Agreement</th>
                        <th className="px-4 py-2 text-center">Agreement Picture</th>
                        <th className="px-4 py-2 text-left">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {status === 'loading' ? (
                        <tr>
                            <td colSpan="5">
                                <Loader />
                            </td>
                        </tr>
                    ) : error ? (
                        <tr>
                            <td colSpan="5">Error: {error}</td>
                        </tr>
                    ) : currentItems.length > 0 ? (
                        currentItems.map((user) => (
                            <tr key={user._id}>
                                <td className="px-4 py-2">
                                    <div className="flex gap-2 items-center">
                                        <img
                                            src={user.user_id.profile_img ? user.user_id.profile_img : userImage}
                                            className="w-10 h-10 rounded-full"
                                            alt="User"
                                        />
                                        <div>
                                            <p className="font-semibold">{user.user_id.fullName}</p>
                                            <p className="text-gray-500">{user.user_id.clientID}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-4 py-2">{user.channelName}</td>
                                <td className="px-4 py-2 text-center">
                                    <div className="flex items-center justify-center">
                                        <a href={user.pdfUrl} target="_blank" rel="noopener noreferrer">
                                            <IoEye className="text-2xl" />
                                        </a>
                                    </div>
                                </td>
                                <td className="px-4 py-2 text-center">
                                    <div className="flex items-center justify-center">
                                        <a href={user.instructionPic} target="_blank" rel="noopener noreferrer">
                                            <IoEye className="text-2xl" />
                                        </a>
                                    </div>
                                </td>
                                <td className="px-4 py-2">{new Date(user.date).toLocaleDateString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No users found.</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* <div className="flex justify-center items-center mt-4">
                <nav>
                    <ul className="inline-flex items-center -space-x-px">
                        <li>
                            <button
                                className={`px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700`}
                                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                            >
                                Previous
                            </button>
                        </li>
                        {renderPageNumbers()}
                        <li>
                            <button
                                className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700`}
                                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div> */}
             <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
            />
        </div>
    );
};

export default SyndicateRequest;
