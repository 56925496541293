import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardStats } from '../store/slice/dashSlice'; 
import Loader from '../components/ui/Loader'
import backgroundImage from '../images/boxbg1.jpg'

const AdminStatus = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardStats());
  }, [dispatch]);

  if (dashboard.status === 'loading') {
    return <Loader />
  }

  if (dashboard.status === 'failed') {
    return <div>No data available</div>
  }

  return (
    <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row flex-wrap gap-2">
      <BoxWrapper  >
        <div className="pl-4 ">
          <span className="text-sm font-bold">Total Clients</span>
          <div className="flex items-center">
            <strong className="text-xl  font-semibold">{dashboard.totalClients}</strong>
            <span className="text-sm text-green-500  pl-2">
              +{dashboard.percentageChanges.totalClients}% From Last Months
            </span>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="pl-4">
          <span className="text-sm  font-bold">Total Active Assets</span>
          <div className="flex items-center">
            <strong className="text-xl  font-semibold">{dashboard.totalActiveAssets}</strong>
            <span className="text-sm text-green-500  pl-2">
              +{dashboard.percentageChanges.totalActiveAssets}% From Last Months
            </span>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="pl-4">
          <span className="text-sm  font-bold">Total Visual Channels</span>
          <div className="flex items-center">
            <strong className="text-xl  font-semibold">{dashboard.totalVisualChannels}</strong>
            <span className="text-sm text-green-500  pl-2">
              +{dashboard.percentageChanges.totalVisualChannels}% From Last Months
            </span>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="pl-4">
          <span className="text-sm font-bold">Total MCN Channels</span>
          <div className="flex items-center">
            <strong className="text-xl  font-semibold">{dashboard.totalMcnChannels}</strong>
            <span className="text-sm text-green-500  pl-2">
              +{dashboard.percentageChanges.totalMcnChannels}% From Last Months
            </span>
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
};

function BoxWrapper({ children }) {
  return <div className=" transition   ease-in-out bg-cover bg-center  hover:-translate-y-1 hover:scale-110  rounded-lg p-4 flex-1 border border-gray-200 flex items-center  text-gray-500 "
  style={{ backgroundImage: `url(${backgroundImage})`,
  
    boxShadow: '4px 4px 4px rgba(56, 189, 248, 0.4)',
   }}
  >{children}</div>;
}

export default AdminStatus;
