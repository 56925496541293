import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchAllClaims = createAsyncThunk('claims/fetchAllClaims', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/getAllClaims`, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token')),
    },
  });
  return response.data.data;
});

export const approveClaim = createAsyncThunk('claims/approveClaim', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/StartClaiming/approve/${id}`, {}, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token')),
    },
  });
  return { id, ...response.data };
});

export const rejectClaim = createAsyncThunk('claims/rejectClaim', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/StartClaiming/reject/${id}`, {}, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token')),
    },
  });
  return { id, ...response.data };
});


const claimsSlice = createSlice({
  name: 'claims',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClaims.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllClaims.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllClaims.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(approveClaim.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item._id === action.payload.id);
        if (index !== -1) {
          state.data[index].status = 'Approved';
        }
      })
      .addCase(rejectClaim.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item._id === action.payload.id);
        if (index !== -1) {
          state.data[index].status = 'Rejected';
        }
      });
  },
});

export default claimsSlice.reducer;
