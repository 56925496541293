import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// Thunks for API calls
export const createVisualChannel = createAsyncThunk(
    'channelReport/createVisualChannel',
    async (channelData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v1/api/create/visualChannels`,
                channelData,
                {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message)
        }
    }
)

export const fetchAllVisualChannels = createAsyncThunk(
    'channelReport/fetchAllVisualChannels',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v1/api/getAll/visualChannels`,
                {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message)
        }
    }
)

export const deleteVisualChannel = createAsyncThunk(
    'channelReport/deleteVisualChannel',
    async (channelId, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v1/api/delete/visualChannels/${channelId}`,
                {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message)
        }
    }
)

export const createCmsEntry = createAsyncThunk(
    'channelReport/createCmsEntry',
    async (cmsData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v1/api/createCmsEntry`,
                cmsData,
                {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message)
        }
    }
)

export const fetchAllCmsEntries = createAsyncThunk(
    'channelReport/fetchAllCmsEntries',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v1/api/getCmsEntries`,
                {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message)
        }
    }
)

export const deleteMCNChannel = createAsyncThunk(
    'channelReport/deleteMCNChannel',
    async (channelId, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v1/api/deleteCmsEntry/${channelId}`,
                {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message)
        }
    }
)

// Initial state
const initialState = {
    channels: [],
    cmsEntries: [],
    status: 'idle',
    error: null
}

// Slice
const channelReportSlice = createSlice({
    name: 'channelReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Visual Channels
            .addCase(createVisualChannel.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createVisualChannel.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.channels.push(action.payload)
            })
            .addCase(createVisualChannel.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(fetchAllVisualChannels.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchAllVisualChannels.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.channels = action.payload
            })
            .addCase(fetchAllVisualChannels.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(deleteVisualChannel.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deleteVisualChannel.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.channels = state.channels.filter((channel) => channel.id !== action.meta.arg)
            })
            .addCase(deleteVisualChannel.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            // CMS Entries
            .addCase(createCmsEntry.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createCmsEntry.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.cmsEntries.push(action.payload)
            })
            .addCase(createCmsEntry.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(fetchAllCmsEntries.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchAllCmsEntries.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.cmsEntries = action.payload
            })
            .addCase(fetchAllCmsEntries.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(deleteMCNChannel.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deleteMCNChannel.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.cmsEntries = state.cmsEntries.filter((entry) => entry.id !== action.meta.arg)
            })
            .addCase(deleteMCNChannel.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
    }
})

export default channelReportSlice.reducer
