import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Fetch all claim release requests
export const fetchAllClaimReleaseRequests = createAsyncThunk('release/fetchAllClaimReleaseRequests', async (_, { rejectWithValue }) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token) throw new Error('Authorization token is missing');

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/getAllClaimReleaseRequest`, {
      headers: {
        Authorization: token,
      },
    });
    console.log("fetchAllClaimReleaseRequests response: ", response.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching all claim release requests: ", error);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Approve a claim release request
export const approveClaimReleaseRequest = createAsyncThunk('release/approveClaimReleaseRequest', async (id, { rejectWithValue }) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token) throw new Error('Authorization token is missing');

    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/claimReleaseRequest/approve/${id}`, {}, {
      headers: {
        Authorization: token,
      },
    });
  
    return { id, ...response.data };
  } catch (error) {
    console.error("Error approving claim release request: ", error);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Reject a claim release request
export const rejectClaimReleaseRequest = createAsyncThunk('release/rejectClaimReleaseRequest', async (id, { rejectWithValue }) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token) throw new Error('Authorization token is missing');

    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/claimReleaseRequest/reject/${id}`, {}, {
      headers: {
        Authorization: token,
      },
    });
    console.log("rejectClaimReleaseRequest response: ", response.data);
    return { id, ...response.data };
  } catch (error) {
    console.error("Error rejecting claim release request: ", error);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const claimsSlice = createSlice({
  name: 'release',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch All Claim Release Requests
      .addCase(fetchAllClaimReleaseRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllClaimReleaseRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllClaimReleaseRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Approve Claim Release Request
      .addCase(approveClaimReleaseRequest.fulfilled, (state, action) => {
        console.log("approveClaimReleaseRequest action payload: ", action.payload);
        const index = state.data.findIndex((item) => item._id === action.payload.id);
        if (index !== -1) {
          state.data[index].status = 'Approved';
        }
      })
      .addCase(approveClaimReleaseRequest.rejected, (state, action) => {
        state.error = action.payload;
      })

      // Reject Claim Release Request
      .addCase(rejectClaimReleaseRequest.fulfilled, (state, action) => {
        console.log("rejectClaimReleaseRequest action payload: ", action.payload);
        const index = state.data.findIndex((item) => item._id === action.payload.id);
        if (index !== -1) {
          state.data[index].status = 'Rejected';
        }
      })
      .addCase(rejectClaimReleaseRequest.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default claimsSlice.reducer;
