import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
// const backgroundImage = require('../../images/synbg.png');

const CustomAlert = ({ show, icon, title, message, onClose }) => {
    React.useEffect(() => {
        if (show) {
            MySwal.fire({
                icon,
                title: `<span class="text-xl font-bold">${title}</span>`,
                html: `<p class="text-lg ">${message}</p>`,
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    icon: 'custom-swal-icon',
                    htmlContainer: 'custom-swal-html',
                },
                showConfirmButton: false,
                timer: 5500,
                didOpen: () => {
                    const popup = Swal.getPopup();
                    if (popup) {
                        // popup.style.backgroundImage = `url(${backgroundImage})`;
                        popup.style.backgroundSize = 'cover';
                        popup.style.backgroundPosition = 'center';
                    }
                },
                willClose: onClose
            });
        }
    }, [show, icon, title, message, onClose]);

    return null;
};

export default CustomAlert;
