import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoMdAddCircle } from 'react-icons/io';
import Modal from '../components/ui/Modal';
import { createVisualChannel } from '../store/slice/channelReportSlice';
import backImage from '../images/back.png';
import Swal from 'sweetalert2';

export default function AddVisualChannel({ userId, syndicationAuthorizationId, userName, onAddSuccess }) {
    const [open, setOpen] = useState(false);
    const [clientName, setClientName] = useState(userName);
    const [channelName, setChannelName] = useState('');
    const [channelUrl, setChannelUrl] = useState('');
    const [sharing, setSharing] = useState('');

    const dispatch = useDispatch();

    const handleSubmit = () => {
        const newChannel = {
            clientName,
            channelName,
            channelUrl,
            sharing,
            user_id: userId,
            SyndicationAuthorization_id: syndicationAuthorizationId,
        };
    
        dispatch(createVisualChannel(newChannel))
            .unwrap()
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Channel Created',
                    text: 'The visual channel has been created successfully!',
                    timer: 3000,
                    showConfirmButton: false,
                });
    
                // Reset the form fields after successful submission
                setChannelName('');
                setChannelUrl('');
                setSharing('');
    
                if (onAddSuccess) {
                    onAddSuccess();
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Creation Failed',
                    text: 'There was an error creating the visual channel. Please Syndicate Request Created or Not.',
                    timer: 5000,
                    showConfirmButton: false,
                });
                console.error('Failed to create channel:', error);
            });
    
        setOpen(false);
    };
    
    return (
        <main className="App">
            <button className='h-6 w-6 cursor-pointer' onClick={() => setOpen(true)}>
                <IoMdAddCircle />
            </button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div className="w-[30vw] bg-black h-auto border border-green-500 rounded-lg"
                    style={{
                        backgroundImage: `url(${backImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                    <div className="flex justify-between p-4 border-b border-green-500">
                        <div>
                            <h1 className="text-white font-semibold text-xl">Add Visual Channel</h1>
                        </div>
                    </div>
                    <div className="modal-body px-4 py-6 flex flex-col gap-4">
                        <div className="flex flex-col w-full text-white">
                            <label htmlFor="clientName" className="flex flex-col items-start gap-1">
                                Client Name
                                <input
                                    type="text"
                                    id="clientName"
                                    name="clientName"
                                    value={userName}
                                    onChange={(e) => setClientName(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                    disabled
                                />
                            </label>
                            <label htmlFor="channelName" className="flex flex-col items-start gap-1">
                                Channel Name
                                <input
                                    type="text"
                                    id="channelName"
                                    name="channelName"
                                    value={channelName}
                                    onChange={(e) => setChannelName(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                            <label htmlFor="channelUrl" className="flex flex-col items-start gap-1">
                                Channel URL
                                <input
                                    type="text"
                                    id="channelUrl"
                                    name="channelUrl"
                                    value={channelUrl}
                                    onChange={(e) => setChannelUrl(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                            <label htmlFor="sharing" className="flex flex-col items-start gap-1">
                                Sharing
                                <input
                                    type="text"
                                    id="sharing"
                                    name="sharing"
                                    value={sharing}
                                    onChange={(e) => setSharing(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                        </div>
                        <button
                            type="button"
                            className="bg-blue-600 text-white py-2 px-4 rounded-md"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </main>
    );
}
