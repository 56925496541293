import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { deleteReportAndPaymentMCNChannel } from '../store/slice/reportSlice'
import { getAllMCNReportsAndPayments, updateApprovalStatus } from '../store/slice/mcnSlice'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import MCNPayment from '../components/MCNpayment'
import Selection from '../components/ui/Selection'
import Loader from '../components/ui/Loader'
import Swal from 'sweetalert2'
import { FaRegTrashCan } from 'react-icons/fa6'
import { IoMdRefreshCircle } from 'react-icons/io'
import { Tooltip } from 'react-tooltip'
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'
import { fetchMCNReport } from '../store/slice/exportSlice'
import Pagination from '../components/shared/Pagination'
const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')
const ReportsPaymentMCN = () => {
    const dispatch = useDispatch()
    const { data: reportsAndPayments, loading: getAllStatus, error: getAllError } = useSelector((state) => state.mcn)

    const [revenueSort, setRevenueSort] = useState(null)
    const [dateSort, setDateSort] = useState('asc')
    const [statusFilter, setStatusFilter] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [reportsPerPage] = useState(15)
    const [exportUrl, setExportUrl] = useState('')

    useEffect(() => {
        dispatch(getAllMCNReportsAndPayments())
    }, [dispatch])

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
    }

    const formatDate1 = (dateString) => {
        const date = new Date(dateString)
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
        return `${date.toLocaleString('default', { month: 'short' })} ${year}`
    }

    const handleStatusChange = async (rowData, newStatus) => {
        try {
            await dispatch(updateApprovalStatus({ id: rowData._id, status: newStatus })).unwrap()
            dispatch(getAllMCNReportsAndPayments())
        } catch (error) {
            console.error(`Error changing status to ${newStatus}:`, error)
        }
    }

    const handleDelete = async (channelId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteReportAndPaymentMCNChannel(channelId)).unwrap()
                    dispatch(getAllMCNReportsAndPayments())
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Your report and payment entry has been deleted.',
                        icon: 'success'
                    })
                } catch (error) {
                    console.error(`Error deleting item with id ${channelId}:`, error)
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to delete the entry.',
                        icon: 'error'
                    })
                }
            }
        })
    }

    const handleExportUsers = async () => {
        try {
            const result = await dispatch(fetchMCNReport()).unwrap()

            setExportUrl(result.url)
        } catch (error) {
            console.error('Error exporting users:', error)
        }
    }

    const renderStatusButton = (rowData) => {
        switch (rowData.status) {
            case 'Approved':
                return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>
            case 'Pending':
                return (
                    <Selection
                        options={[
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Approved', label: 'Approved' }
                        ]}
                        selectedOption={rowData.status}
                        onSelect={(value) => handleStatusChange(rowData, value)}
                        className="bg-black text-white"
                    />
                )
            default:
                return rowData.status
        }
    }

    const handleSort = (column) => {
        if (column === 'revenue') {
            setRevenueSort(revenueSort === 'asc' ? 'desc' : 'asc')
            setDateSort(null)
        } else if (column === 'date') {
            setDateSort(dateSort === 'asc' ? 'desc' : 'asc')
            setRevenueSort(null)
        }
    }

    const sortedData = [...reportsAndPayments]
        .filter((report) => !statusFilter || report.status === statusFilter)
        .sort((a, b) => {
            if (revenueSort) {
                return revenueSort === 'asc' ? a.revenue - b.revenue : b.revenue - a.revenue
            } else if (dateSort) {
                return dateSort === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date)
            }
            return 0
        })

    const indexOfLastReport = currentPage * reportsPerPage
    const indexOfFirstReport = indexOfLastReport - reportsPerPage
    const currentReports = sortedData.slice(indexOfFirstReport, indexOfLastReport)
    const totalPages = Math.ceil(sortedData.length / reportsPerPage);


    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    // const pageNumbers = []
    // for (let i = 1; i <= Math.ceil(sortedData.length / reportsPerPage); i++) {
    //     pageNumbers.push(i)
    // }


    const refreshData = () => {
        dispatch(getAllMCNReportsAndPayments());
    };


    return (
        <div className="w-full bg-cover bg-center flex flex-col gap-4 p-3 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`,}}
        >
            <div className="flex flex-wrap justify-between items-center">
                <div className="flex flex-col gap-2">
                    <h2 className="text-xl font-semibold">Report & Payment</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
              <div className='pr-6'>  <MCNPayment onAddSuccess={refreshData} /></div>
              <button
                    onClick={handleExportUsers}
                    className="transition ease-in-out  hover:-translate-y-1 hover:scale-110  bg-blue-500 p-2 text-white rounded-md"
                >
                    {exportUrl ? (
                        <a href={exportUrl} className="text-white" target="_blank" rel="noopener noreferrer">
                            Download
                        </a>
                    ) : (
                        'Export Users'
                    )}
                </button>
            </div>

            <div className="flex gap-4 mb-4">
                <Selection
                    options={[
                        { value: '', label: 'All' },
                        { value: 'Approved', label: 'Approved' },
                        { value: 'Pending', label: 'Pending' }
                    ]}
                    selectedOption={statusFilter}
                    onSelect={setStatusFilter}
                    className="w-[200px]"
                />
                <button onClick={() => setStatusFilter('')}>
                    <IoMdRefreshCircle className="text-2xl" />
                </button>
            </div>

            {getAllError ? (
                <p>Error: {getAllError}</p>
            ) : getAllStatus ? ( // Show loader while fetching data
                <Loader />
            ) : (
                <div >
                    <div className='max-w-[600px] lg:max-w-full overflow-x-auto '>
                    <Table className="mt-8 bg-white w-full shadow-lg">
                        <Thead className='text-white bg-black'>
                            <Tr>
                                <Th>Client Name</Th>
                                <Th className="text-center">Channel</Th>
                                <Th className="">CMS</Th>
                                <Th className="">Report (Month)</Th>
                                <Th className="">
                                    <div
                                        onClick={() => handleSort('date')}
                                        className="flex items-center cursor-pointer"
                                    >
                                        Date
                                        {dateSort === 'asc' ? (
                                            <FaArrowUp className="ml-2" />
                                        ) : (
                                            <FaArrowDown className="ml-2" />
                                        )}
                                    </div>
                                </Th>
                                <Th className="w-[7rem]">
                                    <div
                                        onClick={() => handleSort('revenue')}
                                        className="flex items-center cursor-pointer"
                                    >
                                        Revenue
                                        {revenueSort === 'asc' ? (
                                            <FaArrowUp className="ml-2" />
                                        ) : (
                                            <FaArrowDown className="ml-2" />
                                        )}
                                    </div>
                                </Th>
                                <Th className="text-center">Status</Th>
                                <Th className="text-center">Comments</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentReports.map((rowData, index) => (
                                <Tr key={index}>
                                    <Td>
                                        <div className="flex gap-2 items-center mr-10 lg:mr-[0px]">
                                            <img
                                                src={rowData.profile_img ? rowData.profile_img : userImage}
                                                className="w-10 h-10 rounded-full"
                                                alt="User"
                                            />
                                            <div>
                                                <p className="font-semibold">{rowData.clientName}</p>
                                                <p className="text-gray-500">{rowData.clientID}</p>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td>{rowData.channelName}</Td>
                                    <Td>{rowData.CMS}</Td>
                                    <Td>{formatDate1(rowData.reportMonth)}</Td>
                                    <Td>{formatDate(rowData.date, true)}</Td>
                                    <Td className="text-center">{rowData.revenue}</Td>
                                    <Td className="flex justify-center items-center">{renderStatusButton(rowData)}</Td>
                                    <Td>
                                        <div className="text-center">
                                            <div
                                                data-tooltip-id={`tooltip-${index}`}
                                                className="cursor-pointer flex items-center justify-center "
                                            >
                                                <BsFillInfoCircleFill />
                                            </div>
                                            <Tooltip id={`tooltip-${index}`} place="top">
                                                {rowData.comments}
                                            </Tooltip>
                                        </div>
                                    </Td>
                                    <Td>
                                        <FaRegTrashCan
                                            className="text-red-500 text-center cursor-pointer"
                                            onClick={() => handleDelete(rowData._id)}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    </div>
                    {/* <nav>
                        <ul className="pagination  flex justify-center mt-4">
                            {pageNumbers.map((number) => (
                                <li key={number} className="page-item">
                                    <button
                                        onClick={() => paginate(number)}
                                        className="page-link p-2  mx-1 rounded"
                                    >
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav> */}
                          <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
            />
                </div>
            )}
        </div>
    )
}

export default ReportsPaymentMCN
