import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWhitelistEntries, approveWhitelistEntry, rejectWhitelistEntry } from '../store/slice/whiteSlice';
import Selection from '../components/ui/Selection';
import Loader from '../components/ui/Loader';
const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')

const Whitelist = () => {
  const dispatch = useDispatch();
  const { data: allUserdata, loading, error } = useSelector((state) => state.whitelist);
  
  useEffect(() => {
    dispatch(fetchWhitelistEntries());
  }, [dispatch]);

  const [selectedOption, setSelectedOption] = useState('All');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    if (selectedOption === 'All') {
      setFilteredUsers(allUserdata);
    } else {
      const filtered = allUserdata.filter(user => user.status === selectedOption);
      setFilteredUsers(filtered);
      setCurrentPage(1);
    }
    
  }, [selectedOption, allUserdata]);

  const options = [
    { value: 'All', label: 'All' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
  ];

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  const handleStatusChange = async (user, newStatus) => {
    try {
      if (newStatus === 'Approved') {
        await dispatch(approveWhitelistEntry(user._id)).unwrap();
      } else if (newStatus === 'Rejected') {
        await dispatch(rejectWhitelistEntry(user._id)).unwrap();
      }
      dispatch(fetchWhitelistEntries());
    } catch (error) {
      console.error(`Error changing status to ${newStatus}:`, error);
    }
  };

  const renderStatusButton = (user) => {
    switch (user.status) {
      case 'Approved':
        return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>;
      case 'Rejected':
        return <button className="bg-red-600 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Rejected</button>;
      case 'Pending':
        return (
          <Selection
            options={[
              { value: 'Pending', label: 'Pending' },
              { value: 'Rejected', label: 'Rejected' },
              { value: 'Approved', label: 'Approved' },
            ]}
            selectedOption={user.status}
            onSelect={(value) => handleStatusChange(user, value)}
            className="bg-black text-white"
          />
        );
      default:
        return user.status;
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 5;

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li key={i}>
            <button
              className={`px-3 py-2 leading-tight ${currentPage === i ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
              onClick={() => paginate(i)}
            >
              {i}
            </button>
          </li>
        );
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) {
        pageNumbers.push(
          <li key={1}>
            <button
              className={`px-3 py-2 leading-tight ${currentPage === 1 ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
              onClick={() => paginate(1)}
            >
              1
            </button>
          </li>
        );

        if (startPage > 2) {
          pageNumbers.push(
            <li key="ellipsis-start">
              <span className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">.....</span>
            </li>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <li key={i}>
            <button
              className={`px-3 py-2 leading-tight ${currentPage === i ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
              onClick={() => paginate(i)}
            >
              {i}
            </button>
          </li>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push(
            <li key="ellipsis-end">
              <span className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">.....</span>
            </li>
          );
        }

        pageNumbers.push(
          <li key={totalPages}>
            <button
              className={`px-3 py-2 leading-tight ${currentPage === totalPages ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
              onClick={() => paginate(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="w-full h-auto bg-cover bg-center p-3 flex flex-col gap-4 shadow-lg rounded-lg"
    style={{ backgroundImage: `url(${backgroundImage})`,}}
    >
      <div className='flex flex-col gap-2'>
        <h3 className='text-xl font-bold'>Whitelist Request</h3>
        <p className='text-md text-gray-500'>Keep Track of Artist Channels and Status.....</p>
      </div>

      <div className='w-20'>
        <Selection
          options={options}
          selectedOption={selectedOption}
          onSelect={handleSelect}
        />
      </div>

      <table className='bg-white shadow-lg'>
        <thead className='text-white bg-black'>
          <tr>
            <th>Username</th>
          
        
            <th>Channel Name</th>
            <th>Channel Url</th>
            <th>Date</th>
            <th className='flex justify-center items-center'>Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6"><Loader/></td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="6">Error: {error}</td>
            </tr>
          ) : currentItems.length > 0 ? (
            currentItems.map(user => (
              <tr key={user._id}>
                <td>
                <div className="flex gap-2 items-center">
                                        <img
                                            src={user.profile_img ? user.profile_img : userImage}
                                            className="w-10 h-10 rounded-full"
                                            alt="User"
                                        />
                                        <div>
                                            <p className="font-semibold">{user.creatorName}</p>
                                            <p className="text-gray-500">{user.clientId}</p>
                                        </div>
                                    </div>
                </td>
                
               
                <td>{user.channelName}</td>
                <td><a href={user.channelUrl} target="_blank" rel="noopener noreferrer">Channel URL</a></td>
                <td>{new Date(user.date).toLocaleDateString()}</td>
                <td className='flex justify-center items-center'>{renderStatusButton(user)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No users found.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="flex justify-center items-center mt-4">
        <nav>
          <ul className="inline-flex items-center -space-x-px">
            <li>
              <button
                className={`px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700`}
                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers()}
            <li>
              <button
                className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700`}
                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Whitelist;
