import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllClaims } from '../store/slice/visualSlice'

import { Link } from 'react-router-dom'

const userImage = require('../images/download.jpeg')

const Recent = () => {
    const dispatch = useDispatch()

    const { data, loading, error } = useSelector((state) => state.claims)

    useEffect(() => {
        dispatch(fetchAllClaims())
    }, [dispatch])

    const pendingUsers = data.filter((user) => user.status === 'Pending')

    const firstTenPendingUsers = pendingUsers.slice(0, 10)

    return (
        <div className=" flex flex-col w-full  shadow-lg">
             <div className='w-full overflow-x-auto '>
             <table className="bg-white w-full shadow-lg ">
                <thead className='bg-black text-white'>
                    <tr>
                        <th>Username</th>
                      
                        <th>Channel Name</th>
                        <th className='text-center'>Asset</th>
                        <th>Video URL</th>
                        <th>Date</th>
                        <th className="flex justify-center items-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {firstTenPendingUsers.map((user) => (
                        <tr key={user.id}>
                            <td>
                            <div className="flex gap-2 items-center mr-10 lg:mr-2">
                                        <img
                                                src={user.profile_img ? user.profile_img : userImage}
                                                className="w-10 h-10 rounded-full"
                                                alt="User"
                                            />
                                            <div>
                                                <p className="font-semibold">{user.creatorName}</p>
                                                <p className="text-gray-500">{user.clientId}</p>
                                            </div>
                                        </div>
                            </td>
                            
                            <td>{user.channelName}</td>
                            <td className='text-center'>{user.assetsName}</td>
                            <td>
                                <a href={user.videoUrl} target="_blank" rel="noopener noreferrer">
                                    Video URL
                                </a>
                            </td>
                            <td>{new Date(user.date).toLocaleDateString()}</td>
                            <td className="text-center">
                                <button className="h-8 font-semibold w-16  rounded bg-black text-white p-1">
                                    {user.status}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <div className="p-2 flex justify-center items-center">
                <Link className="text-white " to="/dashboard/claims">
                    {' '}
                    <button className="bg-blue-500 px-4 py-2 rounded-md">Show All</button>
                </Link>
            </div>
        </div>
    )
}

export default Recent
