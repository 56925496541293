import React from 'react'

const HelpSupport = () => {
    return (
        <>
            <div className="w-full bg-white p-10 flex flex-col items-center gap-8 justify-center">
                <h1 className='text-3xl text-center'>Notices to Brandroute Media should be delivered to:</h1>
                <div>
                   <b> BrandRouteMedia Pvt. Ltd.</b> <br />
                    GALAXY GREENFIELD HEIGHTS", 3rd Floor, Shop No-10th,  <br />
                    Newtown, Rajarhat, 
                    <br />
                    Kolkata, West Bengal. <br /> <br />
                    11.5. Governing Law and Jurisdiction: All disputes arising from this Agreement shall be governed by
                    the laws of the State of West Bengal. In the event of any dispute arising under this Agreement, the
                    Client and Brandroute Media agrees that such disputes will be adjudicated exclusively in the courts
                    of Kolkata, West Bengal, India..
                    <br /> <br />
                    11.6. Legal Expenses: In any legal action related to this Agreement, the prevailing party has the
                    right to recover reasonable legal fees and court costs.
                    <br /> <br />
                    11.7. Entire Agreement: This Agreement constitutes the entire agreement between the parties and
                    supersedes all prior agreements.
                    <br /> <br />
                    11.8. Rights Granted: The Client grants Brandroute Media a non-exclusive right to handle digital
                    rights, including addressing piracy and copyright infringement issues, with the content provided to
                    digital service providers in accordance with the provisions outlined in this Agreement.
                    <br /> <br />
                    11.9. Signatures: This Agreement is executed as of the Effective Date mentioned above by the
                    parties, who, by their signatures below, signify their agreement to all terms and conditions
                    specified herein.
                </div>

                <div className='flex flex-col lg:flex-row md:flex-row gap-10 items-center justify-center'>
                    <div className="w-full lg:w-1/2 flex flex-col gap-2 justify-center items-end">
                        <h1><b>FOR Brandroute Media Pvt. Ltd.</b></h1>
                        <label htmlFor="name">
                            Name : <input className='border border-black ' type="text" />
                        </label>

                    
                        <label htmlFor="name">
                        Tittle /Destination : <input className='border border-black ' type="text" />
                        </label>
                        <label htmlFor="name">
                    Signature : <input className='border border-black ' type="text" />
                        </label>
                        <label htmlFor="name">
                        Date : <input className='border border-black ' type="text" />
                        </label>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col gap-2">
                    <h1><b>FOR CLIENT</b></h1>
                        <label htmlFor="name">
                            Name : <input className='border border-black w-auto' type="text" />
                        </label>

                    
                        <label htmlFor="name">
                        Tittle /Destination : <input className='border border-black ' type="text" />
                        </label>
                        <label htmlFor="name">
                    Signature : <input className='border border-black ' type="text" />
                        </label>
                        <label htmlFor="name">
                        Date : <input className='border border-black ' type="text" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpSupport
