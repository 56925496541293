import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { GoBell } from 'react-icons/go';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { fetchNotifications } from '../../store/slice/notifiSlice';
import Avatar from '../../images/admin.png';
import { resetPassword } from '../../store/slice/authSlices';
import CustomAlert from '../ui/CustomAlert';
import toast from 'react-hot-toast';
import backgroundImage from '../../images/Backround.jpg'


export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.notifications);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

    useEffect(() => {
        dispatch(fetchNotifications());

        const storedEmail = localStorage.getItem('email');
        setEmail(storedEmail ? JSON.parse(storedEmail) : '');
    }, [dispatch]);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setNewPassword('');
        setConfirmPassword('');
    };

    const handlePasswordUpdate = () => {
        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        dispatch(resetPassword({ password: newPassword, confirmPassword, email }))
            .unwrap()
            .then((result) => {
                setAlert({
                    show: true,
                    icon: 'info',
                    title: 'Thank You',
                    message: 'Password reset successful. You will be logged out.',
                });

                setTimeout(() => {
                    localStorage.clear();
                    navigate('/');
                }, 2500);
            })
            .catch((error) => {
                setAlert({
                    show: true,
                    icon: 'error',
                    title: 'Password Reset Failed',
                    message: error.message,
                });
            });
    };

    const firstFiveNotifications = notifications.slice(0, 5);

    return (
        <div className=" bg-white h-12 flex items-center border-b border-gray-200 justify-between w-auto relative pr-12"
      >
            <CustomAlert
                show={alert.show}
                icon={alert.icon}
                title={alert.title}
                message={alert.message}
                onClose={() => setAlert({ show: false, icon: '', title: '', message: '' })}
            />
            <div></div>
            <div className="flex items-center gap-2 mr-2">
                <Popover className="relative">
                    {({ open }) => (
                        <>
                            <Popover.Button
                                className={classNames(
                                    open && 'bg-gray-200',
                                    ' group inline-flex items-center rounded-full p-2 text-gray-700 hover:text-opacity-100 focus:outline-none bg-black shadow-lg'
                                )}
                            >
                                <GoBell fontSize={24} color="white" className="shadow-lg animate-pulse " />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute right-0 z-20 mt-2.5 transform w-[50vw] lg:w-[30vw]">
                                    <div className="bg-indigo-400 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 px-4 py-4">
                                        <strong className="text-white text-xl font-medium">Notifications</strong>
                                        <div className="bg-fixed mt-2 py-2 text-sm max-h-[70vh] overflow-y-auto">
                                            {firstFiveNotifications.map((notification, index) => (
                                                <div key={index} className="border-b border-gray-200 py-2">
                                                    <p className="font-semibold bg-yellow-300 text-md">{notification.title}</p>
                                                    <p className="text-white text-lg">Client ID: {notification.clientID}</p>
                                                    <p className="text-slate-200 text-xs mt-1">
                                                        {format(new Date(notification.notificationCreatedAt), 'PPpp')}
                                                    </p>
                                                </div>
                                            ))}
                                            {notifications.length === 0 && <p>No notifications</p>}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
                <Menu as="div" className="relative">
                    <div >
                        <Menu.Button className=" ml-2  shadow-2xl  bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2  focus:ring-neutral-800">
                            <span className="sr-only">Open user menu</span>
                            <div
                                className="h-10 w-10 rounded-full bg-sky-500 bg-cover border-solid border-2 border-white bg-no-repeat bg-center"
                                style={{ backgroundImage: `url(${Avatar})` }}
                            >
                                <span className="sr-only">User Avatar</span>
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={openModal}
                                        className={classNames(
                                            active && 'bg-gray-100',
                                            'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                        )}
                                    >
                                        Forgot Password
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-md shadow-lg p-6 w-96">
                        <h2 className="text-lg font-semibold mb-4">Forgot Password</h2>
                        <label className="block mb-2">
                            New Password
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                            />
                        </label>
                        <label className="block mb-4">
                            Confirm Password
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                            />
                        </label>
                        <div className="flex justify-end">
                            <button
                                onClick={closeModal}
                                className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handlePasswordUpdate}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
