import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    data: [],
    searchData: [],
    loading: false,
    searchLoading: false,
    error: null,
    searchError: null
}

export const createMCNReportAndPayment = createAsyncThunk('mcn/createMCNReportAndPayment', async (formData) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/create/ReportAndPaymentMCNChannel`,
        formData,
        {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('token')),
                'Content-Type': 'application/json'
            }
        }
    )
    return response.data
})

export const getAllMCNReportsAndPayments = createAsyncThunk('mcn/getAllMCNReportsAndPayments', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/get/ReportAndPaymentMCNChannels`, {
        headers: {
            Authorization: JSON.parse(localStorage.getItem('token'))
        }
    })
    return response.data.data
})

export const searchMCNChannels = createAsyncThunk('mcn/searchMCNChannels', async (searchTerm) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/search/ReportAndPaymentMCNChannels?searchMcn=${searchTerm}`,
        { searchMcn: searchTerm },
        {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('token')),
                'Content-Type': 'application/json'
            }
        }
    )
    return response.data.data
})

export const updateApprovalStatus = createAsyncThunk('mcn/updateApprovalStatus', async ({ id }) => {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/v1/api/${id}/mcnStatus`,
            {},
            {
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('token')),
                    'Content-Type': 'application/json'
                }
            }
        )
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message || error.message)
    }
})

const mcnSlice = createSlice({
    name: 'mcn',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createMCNReportAndPayment.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(createMCNReportAndPayment.fulfilled, (state, action) => {
                state.data.push(action.payload)
                state.loading = false
                state.error = null
            })
            .addCase(createMCNReportAndPayment.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            .addCase(getAllMCNReportsAndPayments.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getAllMCNReportsAndPayments.fulfilled, (state, action) => {
                state.data = action.payload
                state.loading = false
                state.error = null
            })
            .addCase(getAllMCNReportsAndPayments.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            .addCase(searchMCNChannels.pending, (state) => {
                state.searchLoading = true
                state.searchError = null
            })
            .addCase(searchMCNChannels.fulfilled, (state, action) => {
                state.searchData = action.payload
                state.searchLoading = false
                state.searchError = null
            })
            .addCase(searchMCNChannels.rejected, (state, action) => {
                state.searchLoading = false
                state.searchError = action.error.message
            })
            .addCase(updateApprovalStatus.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(updateApprovalStatus.fulfilled, (state, action) => {
                state.approvalStatus = action.payload
                state.loading = false
                state.error = null
            })
            .addCase(updateApprovalStatus.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    }
})

export default mcnSlice.reducer
