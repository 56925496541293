import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchApprovedUsers } from '../store/slice/usersSlice'
import { searchUsers, exportApprovedUsers } from '../store/slice/syndicateSlice'
import { toggleClientStatus } from '../store/slice/addUserSlice'
import {
    fetchAllVisualChannels,
    fetchAllCmsEntries,
    deleteVisualChannel,
    deleteMCNChannel
} from '../store/slice/channelReportSlice'
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import AddVisualChannel from '../components/AddVisualChannel'
import AddMcnChannel from '../components/AddMcnChannel'
import Documents from '../components/ui/Documents'
import { FaTrash } from 'react-icons/fa'
import Modal from '../components/ui/Modal'
import Swal from 'sweetalert2'
import AddVisualPaymantIDwise from '../components/AddVisualPaymantIDwise'
import AddMcnPaymentIDwise from '../components/AddMcnPaymentIDwise'
import { GrPowerReset } from 'react-icons/gr'
import { FaSearch } from 'react-icons/fa'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs'
import AddUserAdmin from '../components/AddUserAdmin'
import UpdateUserAdmin from '../components/UpdateUserAdmin'
const backgroundImage = require('../images/Backround.jpg')
const userImage = require('../images/download.jpeg')
const PAGE_SIZE = 15

const Clients = () => {
    const dispatch = useDispatch()
    const { approvedUsers = [], loading: usersLoading, error: usersError } = useSelector((state) => state.users)
    const { searchResults = [], loading: searchLoading, error: searchError } = useSelector((state) => state.syndicate)
    const {
        channels: visualChannels = [],
        status: visualChannelStatus,
        error: visualChannelError
    } = useSelector((state) => state.channelReport)
    const {
        cmsEntries = [],
        status: cmsChannelStatus,
        error: cmsChannelError
    } = useSelector((state) => state.channelReport)

    const [currentPage, setCurrentPage] = useState(1)
    const [selectedUser, setSelectedUser] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const [exportUrl, setExportUrl] = useState('')

    useEffect(() => {
        dispatch(fetchApprovedUsers())
        dispatch(fetchAllVisualChannels())
        dispatch(fetchAllCmsEntries())
    }, [dispatch])

    useEffect(() => {
        if (approvedUsers.length > 0) {
        }
        if (usersError) {
            console.error('Error fetching approved users:', usersError)
        }
    }, [approvedUsers, usersLoading, usersError])

    useEffect(() => {
        if (visualChannels.length > 0) {
        }
        if (visualChannelError) {
            console.error('Error fetching visual channels:', visualChannelError)
        }
    }, [visualChannels, visualChannelStatus, visualChannelError])

    useEffect(() => {
        if (cmsChannelStatus === 'loading') {
            console.log('Fetching CMS entries...')
        }
        if (cmsChannelError) {
            console.error('Error fetching CMS entries:', cmsChannelError)
        }
        if (cmsEntries.length > 0) {
        }
    }, [cmsEntries, cmsChannelStatus, cmsChannelError])

    useEffect(() => {
        if (isSearching) {
        }
        if (searchError) {
            console.error('Error searching users:', searchError)
        }
    }, [searchResults, searchLoading, searchError, isSearching])

    const handleDeleteVisualChannel = (channelId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteVisualChannel(channelId)).then(() => {
                    dispatch(fetchAllVisualChannels())
                    dispatch(fetchAllCmsEntries())
                })
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Your visual channel has been deleted.',
                    icon: 'success'
                })
            }
        })
    }

    const handleDeleteCMSChannel = (channelId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMCNChannel(channelId)).then(() => {
                    dispatch(fetchAllVisualChannels())
                    dispatch(fetchAllCmsEntries())
                })
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Your CMS channel has been deleted.',
                    icon: 'success'
                })
            }
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const openContactInfoModal = (user) => {
        setSelectedUser(user)
    }

    const closeContactInfoModal = () => {
        setSelectedUser(null)
    }

    const handleAddSuccess = () => {
        dispatch(fetchApprovedUsers())
        dispatch(fetchAllVisualChannels())
        dispatch(fetchAllCmsEntries())
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchValue.trim() !== '') {
            dispatch(searchUsers(searchValue));
            setIsSearching(true);
            setCurrentPage(1); 
        } else {
            dispatch(fetchApprovedUsers());
            setIsSearching(false);
            setCurrentPage(1); 
        }
    };
    

    const handleResetSearch = () => {
        setSearchValue('')
        dispatch(fetchApprovedUsers())
        setIsSearching(false)
        setCurrentPage(1)
    }

    const handleExportUsers = async () => {
        try {
            const result = await dispatch(exportApprovedUsers()).unwrap()

            setExportUrl(result)
        } catch (error) {
            console.error('Error exporting users:', error)
        }
    }

    const handleToggleStatus = (userId, currentStatus) => {
        const result = dispatch(toggleClientStatus({ clientId: userId, isActive: !currentStatus }))
            .unwrap()
            .then((result) => {
                dispatch(fetchApprovedUsers()).then(() => {
                    Swal.fire({
                        title: 'Success!',
                        text: 'User status updated successfully.',
                        icon: 'success'
                    })
                })
            })
            .catch((error) => {
                console.error('Failed to toggle status:', error)
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to update user status. Please try again.',
                    icon: 'error'
                })
            })
    }

    const usersToDisplay = isSearching ? searchResults : approvedUsers;
    const totalPages = Math.ceil(usersToDisplay.length / PAGE_SIZE);
    const maxDisplayedPages = 5
    return (
        <div
            className="w-full h-auto bg-center bg-cover p-3 flex flex-col gap-4 shadow-lg rounded-lg"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="flex items-center justify-between flex-wrap">
                <div className="flex flex-col gap-2">
                    <h3 className="text-xl font-bold">All Clients</h3>
                    <p className="text-md text-gray-500 mb-4">Keep Track of Syndicate Clients and Contacts.....</p>
                </div>
                <div className="flex gap-2"> 
                    <button
                        onClick={handleExportUsers}
                        className="transition ease-in-out  hover:-translate-y-1 hover:scale-110  bg-blue-500 p-2 text-white rounded-md"
                    >
                        {exportUrl ? (
                            <a href={exportUrl} className="text-white" target="_blank" rel="noopener noreferrer">
                                Download
                            </a>
                        ) : (
                            'Export Users'
                        )}
                    </button>

                    <AddUserAdmin onAddSuccess={handleAddSuccess} />
                </div>
                <form className="flex gap-2" onSubmit={handleSearchSubmit}>
                    <input
                        className="w-[15rem] border border-black rounded-xl bg-transparent px-4 py-2"
                        type="search"
                        placeholder="Search name..."
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                    <button type="submit" className="bg-blue-500 p-2 rounded-lg">
                        <FaSearch className="text-xl text-white" />
                    </button>
                    <button type="button" className="bg-red-500 p-2 rounded-lg" onClick={handleResetSearch}>
                        <GrPowerReset className="h-5 w-5 text-white" />
                    </button>
                </form>
            </div>

            <div className="max-w-[600px] lg:max-w-full ">
                <Table className="bg-white w-full shadow-md">
                    <Tbody>
                        {usersToDisplay
                            .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                            .map((user, index) => (
                                <Tr key={index}>
                                    <Td>
                                        <img
                                            src={user.profile_img ? user.profile_img : userImage}
                                            className="w-10 h-10 rounded-full"
                                            alt="User"
                                        />
                                    </Td>
                                    <Td>
                                        <div className="flex flex-col">
                                            <p>{user.fullName}</p>
                                            <p>{user.clientID}</p>
                                        </div>
                                    </Td>
                                    <Td>
                                        <button
                                            className="text-blue-500 hover:underline"
                                            onClick={() => openContactInfoModal(user)}
                                        >
                                            Info
                                        </button>
                                    </Td>
                                    <Td>
                                        <Documents user={user} />
                                    </Td>
                                    <Td className="flex justify-center items-center">
                                        <details className="dropdown">
                                            <summary className="m-1 btn">
                                                Visual Channel
                                                {visualChannels.some((channel) => channel.user_id === user._id) && (
                                                    <span className="text-2xl text-green-500">*</span> // This adds a checkmark if the channel is present
                                                )}
                                            </summary>
                                            <ul className="overflow-y-scroll max-h-[20rem] p-2 shadow menu dropdown-content bg-white border border-black z-[2] bg-base-100 rounded-box w-72 absolute">
                                                {visualChannels.length > 0 ? (
                                                    visualChannels
                                                        .filter((channel) => channel.user_id === user._id)
                                                        .map((channel) => (
                                                            <li
                                                                key={channel._id}
                                                                className="border border-black p-1 flex justify-between items-center"
                                                            >
                                                                <span>
                                                                    {channel.channelName} || {channel.sharing}
                                                                </span>
                                                                <button
                                                                    className="ml-2 p-1 text-red-600 hover:text-red-800"
                                                                    onClick={() =>
                                                                        handleDeleteVisualChannel(channel._id)
                                                                    }
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                            </li>
                                                        ))
                                                ) : (
                                                    <li>
                                                        <span>No Visual Channels</span>
                                                    </li>
                                                )}
                                            </ul>
                                        </details>
                                        <AddVisualChannel
                                            userId={user._id}
                                            userName={user.fullName}
                                            syndicationAuthorizationId={
                                                user.syndicationAuthorizationData.length > 0
                                                    ? user.syndicationAuthorizationData[0]._id
                                                    : ''
                                            }
                                            onAddSuccess={handleAddSuccess}
                                        />
                                    </Td>
                                    <Td>
                                        <div className="flex justify-center items-center">
                                            <details className="dropdown">
                                                <summary className="m-1 btn">
                                                    CMS Channel
                                                    {cmsEntries.some((entry) => entry.MCN_id?.user_id === user._id) && (
                                                        <span className="text-2xl text-green-500">*</span> // This adds a checkmark if the channel is present
                                                    )}
                                                </summary>
                                                <ul className=" overflow-y-scroll max-h-[20rem] p-2 shadow menu dropdown-content border border-black bg-white z-[2] bg-base-100 rounded-box w-72 absolute right-0">
                                                    {cmsEntries.length > 0 ? (
                                                        cmsEntries
                                                            .filter((entry) => entry.MCN_id?.user_id === user._id)
                                                            .map((entry) => (
                                                                <li
                                                                    key={entry._id}
                                                                    className="border border-black p-1 flex justify-between items-center"
                                                                >
                                                                    <span>
                                                                        {entry.channelName} | {entry.cmsName} |{' '}
                                                                        {entry.sharing}
                                                                    </span>
                                                                    <button
                                                                        className="ml-2 p-1 text-red-600 hover:text-red-800"
                                                                        onClick={() =>
                                                                            handleDeleteCMSChannel(entry._id)
                                                                        }
                                                                    >
                                                                        <FaTrash />
                                                                    </button>
                                                                </li>
                                                            ))
                                                    ) : (
                                                        <li>
                                                            <span>No CMS Channels</span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </details>
                                            <AddMcnChannel
                                                userId={user._id}
                                                linkAndCreateChannelId={
                                                    user.linkAndCreateChannelData.length > 0
                                                        ? user.linkAndCreateChannelData[0]._id
                                                        : ''
                                                }
                                                onAddSuccess={handleAddSuccess}
                                            />
                                        </div>
                                    </Td>
                                    <Td>
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={user.isActive}
                                                onChange={() => handleToggleStatus(user._id, user.isActive)}
                                                className="sr-only peer"
                                            />
                                            <div
                                                className={`relative w-11 h-6 rounded-full peer peer-checked:bg-blue-600 ${
                                                    user.isActive ? 'bg-blue-600' : 'bg-gray-200'
                                                } peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
                                            />
                                        </label>
                                    </Td>

                                    <Td>
                                        <details className="dropdown">
                                            <summary className="m-1 btn">Reports</summary>
                                            <ul className=" overflow-y-scroll max-h-[20rem] shadow menu dropdown-content bg-white border border-black z-[2] bg-base-100 rounded-box w-auto absolute">
                                                <li className=" flex justify-between items-center">
                                                    <AddVisualPaymantIDwise userId={user.clientID} />
                                                </li>
                                                <li className=" flex justify-between items-center">
                                                    <AddMcnPaymentIDwise userId={user.clientID} />
                                                </li>
                                            </ul>
                                        </details>
                                    </Td>
                                    <Td>
                                        <UpdateUserAdmin userId={user._id} onUpdateSuccess={handleAddSuccess} />
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </div>

             {/* PAGINATION PART  */}
        <div className="flex justify-center mt-4">
            {currentPage > 1 && (
                <div
                    className="mx-1 px-3 py-1 rounded bg-white text-gray-700 cursor-pointer"
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    <BsChevronDoubleLeft className="text-2xl" />
                </div>
            )}

            {Array.from({ length: totalPages }, (_, i) => {
                const pageIndex = i + 1;

                // Show the first five pages
                if (pageIndex <= maxDisplayedPages || pageIndex === totalPages || (currentPage > maxDisplayedPages && pageIndex === currentPage)) {
                    return (
                        <button
                            key={pageIndex}
                            className={`mx-1 px-3 py-1 rounded ${
                                currentPage === pageIndex ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
                            }`}
                            onClick={() => handlePageChange(pageIndex)}
                        >
                            {pageIndex}
                        </button>
                    );
                }

                // Show ellipsis if needed
                if (pageIndex === maxDisplayedPages + 1 && currentPage < totalPages - 1) {
                    return <span key={pageIndex}>...</span>;
                }

                return null;
            })}

            {currentPage < totalPages && (
                <div
                    className="mx-1 px-3 py-1 rounded bg-white text-gray-700 cursor-pointer"
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    <BsChevronDoubleRight className="text-2xl" />
                </div>
            )}
        </div>
        {/* PAGINATION END  */}

            {/* Contact Info Modal */}
            {selectedUser && (
                <Modal open={selectedUser !== null} onClose={closeContactInfoModal}>
                    <div className="p-4 w-[80vw] lg:w-[40vw] h-auto bg-black rounded-md ">
                        <div className="grid grid-cols-2 gap-2">
                            <div className="mb-4 flex flex-col gap-2">
                                <label className="block text-sm font-medium text-white">Email :</label>
                                <p className="w-full border border-white p-2 rounded-lg text-sm text-white">
                                    {selectedUser.email}
                                </p>
                            </div>
                            <div className="mb-4 flex flex-col gap-2">
                                <label className="block text-sm font-medium text-white">Phone :</label>
                                <p className="w-full border border-white p-2 rounded-lg text-sm text-white">
                                    {selectedUser.mobileNumber}
                                </p>
                            </div>

                            <div className="mb-4 flex flex-col gap-2">
                                <label className="block text-sm font-medium text-white">Bank Holder :</label>
                                <p className="w-full border border-white p-2 rounded-lg text-sm text-white">
                                    {selectedUser.accountHolderName ? selectedUser.accountHolderName : 'Data not found'}
                                </p>
                            </div>

                            <div className="mb-4 flex flex-col gap-2">
                                <label className="block text-sm font-medium text-white">Bank Name :</label>
                                <p className="w-full border border-white p-2 rounded-lg text-sm text-white">
                                    {selectedUser.bankName ? selectedUser.bankName : 'Data not found'}
                                </p>
                            </div>

                            <div className="mb-4 flex flex-col gap-2">
                                <label className="block text-sm font-medium text-white">IFSC/SWIFT Code :</label>
                                <p className="w-full border border-white p-2 rounded-lg text-sm text-white">
                                    {selectedUser.ifscCode ? selectedUser.ifscCode : 'Data not found'}
                                </p>
                            </div>

                            <div className="mb-4 flex flex-col gap-2">
                                <label className="block text-sm font-medium text-white">Account No :</label>
                                <p className="w-full border border-white p-2 rounded-lg text-sm text-white">
                                    {selectedUser.accountNumber ? selectedUser.accountNumber : 'Data not found'}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Clients
