import React, { useState } from 'react';

const Readmore = ({ children, maxCharacterCount }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  if (children.length <= maxCharacterCount) {
    return <p>{children}</p>;
  }

  return (
    <p>
      {isExpanded ? children : `${children.substring(0, maxCharacterCount)}... `}
      <button onClick={toggleExpansion} className="text-blue-500 hover:underline">
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </p>
  );
};

export default Readmore;
