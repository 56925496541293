import React from 'react'

const Pagination = ({ currentPage, totalPages, paginate }) => {
    const renderPagination = () => {
        const pageNumbers = []
        const maxPagesToShow = 5
        const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1)
        const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages)

        if (startPage > 1) {
            pageNumbers.push(1)
            if (startPage > 2) {
                pageNumbers.push('...')
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i)
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push('...')
            }
            pageNumbers.push(totalPages)
        }

        return pageNumbers.map((page, index) => (
            <li key={index}>
                {page === '...' ? (
                    <span className="px-3 py-2">...</span>
                ) : (
                    <button
                        className={`px-3 py-2 leading-tight ${
                            currentPage === page ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                        } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                        onClick={() => paginate(page)}
                    >
                        {page}
                    </button>
                )}
            </li>
        ))
    }

    return (
        <div className="flex justify-center items-center mt-4">
            <nav>
                <ul className="inline-flex items-center -space-x-px">
                    <li>
                        <button
                            className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                        >
                            Previous
                        </button>
                    </li>

                    {renderPagination()}

                    <li>
                        <button
                            className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
                            onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Pagination
