import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for login
export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/admin/login`, credentials);
        return response.data;
    } catch (error) {
        if (error.response) {
            return thunkAPI.rejectWithValue(error.response.data.message || 'Failed to login');
        } else {
            return thunkAPI.rejectWithValue(error.message || 'Network error');
        }
    }
});

// Async thunk for reset password
export const resetPassword = createAsyncThunk('auth/resetPassword', async ({ email, password, confirmPassword }, thunkAPI) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/api/admin/reset-password`,
            { email, password, confirmPassword },
            {
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('token')),
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            return thunkAPI.rejectWithValue(error.response.data.message || 'Failed to reset password');
        } else {
            return thunkAPI.rejectWithValue(error.message || 'Network error');
        }
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        logout(state) {
            state.user = null;
            state.status = 'idle';
            state.error = null;
            localStorage.removeItem('token');
            localStorage.removeItem('email');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.data;
                localStorage.setItem('token', JSON.stringify(action.payload.data.token));
                localStorage.setItem('email', JSON.stringify(action.payload.data.email));
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(resetPassword.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
