import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import Modal from '../components/ui/Modal';
import { createVisualReportAndPayment, searchVisualChannels } from '../store/slice/reportSlice';
import Selection from '../components/ui/Selection';
import CustomAlert from '../components/ui/CustomAlert';  

export default function VisualPayment({ onAddSuccess }) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        clientID: '',
        clientName: '',
        reportMonth: '',
        date: new Date().toISOString().split('T')[0],
        channelName: '',
        revenue: '',
        view: '',
        comments: ''
    });

    const [channelOptions, setChannelOptions] = useState([]);
    const [addedChannels, setAddedChannels] = useState([]);
    const [showAlert, setShowAlert] = useState(false); 
    const [alertContent, setAlertContent] = useState({});  

    const dispatch = useDispatch();
    const { searchData } = useSelector((state) => state.reportAndPaymentVisual);

    useEffect(() => {
        if (searchData.length > 0) {
            const userData = searchData[0].user;
            const channelNames = searchData[0].channelNames.map((channel) => ({
                value: channel.id,
                label: channel.name
            }));

            setFormData({
                clientID: userData.clientID,
                clientName: userData.fullName,
                reportMonth: '',
                date: new Date().toISOString().split('T')[0],
                channelName: '',
                revenue: '',
                view: '',
                comments: ''
            });

            setChannelOptions(channelNames);
        }
    }, [searchData]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSelectChange = (value) => {
        setFormData({
            ...formData,
            channelName: value
        });
    };

    const handleAddPayment = () => {
        const selectedChannel = channelOptions.find(option => option.value === formData.channelName);

        const newPayment = {
            channelName: selectedChannel ? selectedChannel.label : '',
            view: formData.view,
            revenue: formData.revenue,
            comments: formData.comments,
            reportMonth: formData.reportMonth,
        };

        setAddedChannels([...addedChannels, newPayment]);

        setFormData({
            ...formData,
            channelName: '',
            revenue: '',
            view: '',
            comments: '',
            reportMonth: ''
        });
    };

    const handleSubmit = () => {
        const dataToSubmit = {
            claims: addedChannels.map(({ channelName, revenue, view, comments, reportMonth }) => ({
                clientID: formData.clientID,
                clientName: formData.clientName,
                channelName: channelName,
                reportMonth: reportMonth,
                view: view,
                date: formData.date,
                revenue: parseFloat(revenue),
                comments: comments,
                user_id: searchData.length > 0 ? searchData[0].user._id : ''
            }))
        };
    
        dispatch(createVisualReportAndPayment(dataToSubmit))
            .unwrap()
            .then((response) => {
                if (response.status === "success") { 
                    setOpen(false);
                    setShowAlert(true);  
                    setAlertContent({
                        icon: 'success',
                        title: 'Thank You',
                        message: 'Visual Channel Payment Records Created Successfully',
                    });
                    resetForm();
                    if (onAddSuccess) {
                        onAddSuccess();
                    }
                } else {
                    setShowAlert(true);  
                    setAlertContent({
                        icon: 'error',
                        title: 'Failed',
                        message: "Failed to Create Visual Report!",
                    });
                    resetForm();
                }
            })
            .catch((error) => {
                setShowAlert(true);  
                setAlertContent({
                    icon: 'error',
                    title: 'Error',
                    message: 'Failed to Create Visual Report!',
                });
                resetForm();
            });
    };

    const handleSearch = (searchTerm) => {
        dispatch(searchVisualChannels(searchTerm));
    };

    const resetForm = () => {
        setFormData({
            clientID: '',
            clientName: '',
            reportMonth: '',
            date: new Date().toISOString().split('T')[0],
            channelName: '',
            revenue: '',
            view: '',
            comments: ''
        });
        setAddedChannels([]);
    };

    return (
        <main className="App">
            <button className="transition rounded-lg ease-in-out  hover:-translate-y-1 hover:scale-110   p-2 text-white bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500" onClick={() => setOpen(true)}>
                Create Payment
            </button>

            <Modal open={open} onClose={() => { setOpen(false); resetForm(); }}>
                <div className="rounded-md bg-black border-green-500 p-4">
                    <div className="modal-body w-[60vw] lg:w-[30vw] pt-4 h-auto flex flex-col gap-2">
                        <div className="w-full relative">
                            <input
                                type="text"
                                id="search"
                                name="search"
                                className="border w-full text-white border-white rounded-md p-2 bg-transparent pl-10"
                                placeholder="Search here"
                                onBlur={(e) => handleSearch(e.target.value)}
                            />
                            <FaSearch className="absolute right-2 cursor-pointer top-1/2 transform -translate-y-1/2 text-white" />
                        </div>
                       
                        <div className="grid grid-cols-2 gap-2 text-white">
                            <label htmlFor="clientID" className='flex flex-col gap-2'>
                                Client ID
                                <input
                                type="text"
                                id="clientID"
                                name="clientID"
                                value={formData.clientID}
                                onChange={handleInputChange}
                                className="border border-white rounded-md p-2 bg-transparent"
                                placeholder="Client ID"
                            />
                            </label>
                            <label htmlFor="clientName" className="flex flex-col gap-2">
                                Client Name
                                <input
                                    type="text"
                                    id="clientName"
                                    name="clientName"
                                    value={formData.clientName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Client Name"
                                />
                            </label>
                            <label htmlFor="channelName" className="flex flex-col gap-2">
                                Channel Name
                                <Selection
                                    options={channelOptions}
                                    selectedOption={formData.channelName}
                                    onSelect={(selectedValue) => handleSelectChange(selectedValue)}
                                />
                            </label>
                            <label htmlFor="reportMonth" className="flex flex-col gap-2">
                                Reporting Month
                                <input
                                    type="month"
                                    id="reportMonth"
                                    name="reportMonth"
                                    value={formData.reportMonth}
                                    onChange={handleInputChange}
                                    className="border border-white  rounded-md p-2 bg-transparent"
                                    placeholder="Reporting Month"
                                />
                            </label>
                            <label htmlFor="date" className="flex flex-col gap-2">
                                Date
                                <input
                                    type="text"
                                    id="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Date"
                                />
                            </label>

                            <label htmlFor="view" className="flex flex-col gap-2">
                                Views
                                <input
                                    type="text"
                                    id="view"
                                    name="view"
                                    value={formData.view}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="view"
                                />
                            </label>
                            <label htmlFor="revenue" className="flex flex-col gap-2">
                                Revenue
                                <input
                                    type="text"
                                    id="revenue"
                                    name="revenue"
                                    value={formData.revenue}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Revenue"
                                />
                            </label>
                            <label htmlFor="comments" className="flex flex-col gap-2">
                                Comments
                                <input
                                    type="text"
                                    id="comments"
                                    name="comments"
                                    value={formData.comments}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Comments"
                                />
                            </label>
                        </div>
                       
                        

                        <button
                            type="button"
                            onClick={handleAddPayment}
                            className="bg-gray-400 p-1 font-medium text-white rounded-md"
                        >
                            + Add payment
                        </button>

                        <div className="overflow-y-auto max-h-20 mt-4">
                            <table className="border-collapse w-full text-white border border-white">
                                <thead>
                                    <tr>
                                        <th className="p-1 bg-black">Channel Name</th>
                                        <th className="p-1 bg-black">Views</th>
                                        <th className="p-1 bg-black">Revenue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedChannels.map((channel, index) => (
                                        <tr key={index}>
                                            <td>{channel.channelName}</td>
                                            <td>{channel.view}</td>
                                            <td>{channel.revenue}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <button type="button" onClick={handleSubmit} className="btn btn-primary p-2 rounded-md">
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Custom alert component */}
            <CustomAlert
                show={showAlert}
                icon={alertContent.icon}
                title={alertContent.title}
                message={alertContent.message}
                onClose={() => setShowAlert(false)}
            />
        </main>
    );
}
