import React, { useState } from 'react';
import Modal from '../components/ui/Modal';
import { IoMdAddCircle } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { createCmsEntry } from '../store/slice/channelReportSlice';
import Swal from 'sweetalert2';

export default function AddMcnChannel({ userId, linkAndCreateChannelId, onAddSuccess }) {
    const [open, setOpen] = useState(false);
    const [cmsName, setCmsName] = useState('');
    const [channelName, setChannelName] = useState('');
    const [channelUrl, setChannelUrl] = useState('');
    const [sharing, setSharing] = useState('');

    const dispatch = useDispatch();

    const handleSubmit = () => {
        const payload = {
            cmsName,
            channelName,
            channelUrl,
            sharing,
            MCN_id: linkAndCreateChannelId,
            user_id: userId,
        };
    
        dispatch(createCmsEntry(payload))
            .unwrap()
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'CMS created successfully!',
                    timer: 3000,
                    showConfirmButton: false,
                });
    
                // Reset form fields after successful submission
                setCmsName('');
                setChannelName('');
                setChannelUrl('');
                setSharing('');
    
                if (onAddSuccess) {
                    onAddSuccess();
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to create channel. Please Check MCN Request Approved or Not.',
                    timer: 3000,
                    showConfirmButton: false,
                });
            });
    
        setOpen(false);
    };
    

    return (
        <main className="App">
            <IoMdAddCircle className="h-4 w-4 cursor-pointer" onClick={() => setOpen(true)} />

            <Modal open={open} onClose={() => setOpen(false)}>
                <div className="w-[30vw] h-auto bg-black border border-green-500 rounded-lg">
                    <div className="flex justify-between p-4 border-b border-green-500">
                        <div>
                            <h1 className="text-white font-semibold text-xl">Add MCN Channel</h1>
                        </div>
                    </div>
                    <div className="modal-body z-[20] px-4 py-6 flex flex-col gap-4">
                        <div className="flex flex-col w-full text-white">
                            <label htmlFor="cmsName" className="flex flex-col items-start gap-1">
                                CMS Name
                                <input
                                    type="text"
                                    id="cmsName"
                                    name="cmsName"
                                    value={cmsName}
                                    onChange={(e) => setCmsName(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                            <label htmlFor="channelName" className="flex flex-col items-start gap-1">
                                Channel Name
                                <input
                                    type="text"
                                    id="channelName"
                                    name="channelName"
                                    value={channelName}
                                    onChange={(e) => setChannelName(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                            <label htmlFor="channelUrl" className="flex flex-col items-start gap-1">
                                Channel URL
                                <input
                                    type="text"
                                    id="channelUrl"
                                    name="channelUrl"
                                    value={channelUrl}
                                    onChange={(e) => setChannelUrl(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                            <label htmlFor="sharing" className="flex flex-col items-start gap-1">
                                Sharing
                                <input
                                    type="text"
                                    id="sharing"
                                    name="sharing"
                                    value={sharing}
                                    onChange={(e) => setSharing(e.target.value)}
                                    className="border border-white rounded-md p-2 bg-transparent w-full"
                                />
                            </label>
                        </div>
                        <button
                            type="button"
                            className="bg-blue-600 text-white py-2 px-4 rounded-md"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </main>
    );
}
