import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreateChannelEntries, approveCreateChannelEntry, rejectCreateChannelEntry } from '../store/slice/channelSlice';
import Selection from '../components/ui/Selection';
import ReadMore from '../components/ui/Readmore'; 
import Loader from '../components/ui/Loader'
import Pagination from '../components/shared/Pagination'
const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')


const Connects = () => {
  const dispatch = useDispatch();
  const { data: allChannelData, loading, error } = useSelector((state) => state.createChannel);

  useEffect(() => {
    dispatch(fetchCreateChannelEntries());
  }, [dispatch]);

  const [selectedOption, setSelectedOption] = useState('All');
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    if (selectedOption === 'All') {
      setFilteredChannels(allChannelData);
    } else {
      const filtered = allChannelData.filter(channel => channel.status === selectedOption);
      setFilteredChannels(filtered);
      setCurrentPage(1);
    }
    //setCurrentPage(1);
  }, [selectedOption, allChannelData]);

  const options = [
    { value: 'All', label: 'All' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
  ];

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  const handleStatusChange = async (channel, newStatus) => {
    try {
      if (newStatus === 'Approved') {
        await dispatch(approveCreateChannelEntry(channel._id)).unwrap();
      } else if (newStatus === 'Rejected') {
        await dispatch(rejectCreateChannelEntry(channel._id)).unwrap();
      }
      dispatch(fetchCreateChannelEntries());
    } catch (error) {
      console.error(`Error changing status to ${newStatus}:`, error);
    }
  };

  const renderStatusButton = (channel) => {
    switch (channel.status) {
      case 'Approved':
        return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>;
      case 'Rejected':
        return <button className="bg-red-600 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Rejected</button>;
      case 'Pending':
        return (
          <Selection
            options={[
              { value: 'Pending', label: 'Pending' },
              { value: 'Rejected', label: 'Rejected' },
              { value: 'Approved', label: 'Approved' },
            ]}
            selectedOption={channel.status}
            onSelect={(value) => handleStatusChange(channel, value)}
            className="bg-black text-white"
          />
        );
      default:
        return channel.status;
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredChannels.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredChannels.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="w-full h-auto bg-center bg-cover  p-3 flex flex-col gap-4 shadow-lg rounded-lg"
    style={{ backgroundImage: `url(${backgroundImage})`,}}
    >
      <div className='flex flex-col gap-2'>
        <h3 className='text-xl font-bold'>MCN Connects</h3>
        <p className='text-md text-gray-500'>Keep Track of Channels and their Status.....</p>
      </div>

      <div className='w-20'>
        <Selection
          options={options}
          selectedOption={selectedOption}
          onSelect={handleSelect}
        />
      </div>

      <div className="max-w-[600px] lg:max-w-full overflow-x-auto  shadow-lg">
      <table className="bg-white w-full  px-4">
        <thead className='text-white bg-black'>
          <tr>
            <th>Username</th>
            <th>Channel URL</th>
            <th>Channel Type</th>
            <th className='text-center'>Description</th>
            <th>Copyright</th>
            <th>Earn Section</th>
            <th>Google Drive Link</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7">
                <Loader/>
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="7">Error: {error}</td>
            </tr>
          ) : currentItems.length > 0 ? (
            currentItems.map(channel => (
              <tr key={channel._id}>
                <td className='pr-6'>
                  <div className="flex gap-2 items-center">
                    <img
                      src={channel.profile_img ? channel.profile_img : userImage}
                      className="w-10 h-10 rounded-full"
                      alt="User"
                    />
                    <div>
                      <p className="font-semibold">{channel.fullName}</p>
                      <p className="text-gray-500">{channel.clientID}</p>
                    </div>
                  </div>
                </td>
                <td className='px-4'><a href={channel.channelUrl} target="_blank" rel="noopener noreferrer">Channel URL</a></td>
                <td>{channel.channelType}</td>
                <td className='w-[15rem] text-center'><ReadMore maxCharacterCount={50}>{channel.description}</ReadMore></td> {/* Use ReadMore for description */}
                <td><a href={channel.channelCopyright} target="_blank" rel="noopener noreferrer">Copyright</a></td>
                <td><a href={channel.screenshot} target="_blank" rel="noopener noreferrer">Screenshot</a></td>
                <td><a href={channel.googleDriveLink} target="_blank" rel="noopener noreferrer">Google Drive Link</a></td>
                <td>{renderStatusButton(channel)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No channels found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>

      {/* <div className="flex justify-center items-center mt-4">
        <nav>
          <ul className="inline-flex items-center -space-x-px">
            <li>
              <button
                className={`px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700`}
                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  className={`px-3 py-2 leading-tight ${currentPage === index + 1 ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700`}
                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div> */}

<Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
            />


    </div>
  );
};

export default Connects;
