import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';

export default function Dropdown({ imageSrc, label, links, isCollapsed }) {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const activeLinkClass = 'bg-blue-700';
    const isSubmenuActive = links.some((link) => location.pathname === link.href);

    return (
        <div className="flex flex-col">
            <button
                className={classNames('flex text-[10px] opacity-75 py-1 bg-zinc-700 lg:text-sm items-center gap-2  px-1  hover:bg-blue-300 active:bg-blue-500 rounded-lg text-white', {
                    'justify-center': isCollapsed,
                    [activeLinkClass]: isSubmenuActive
                })}
                onClick={() => setIsOpen(!isOpen)}
            >
                <img src={imageSrc} className="w-6 h-6" alt={label} />
                {!isCollapsed && (
                    <>
                        {label}
                        <FiChevronDown className={classNames('ml-auto transition-transform duration-300', { 'rotate-180': isOpen, 'rotate-0': !isOpen })} />
                    </>
                )}
            </button>

            <div className={classNames('overflow-hidden transition-all duration-500', { 'max-h-0': !isOpen, 'max-h-40': isOpen })}>
                {isOpen && !isCollapsed && (
                    <div className="pl-8 flex flex-col border-l-2  border-blue-300">
                        {links.map((link) => (
                            <Link key={link.label} to={link.href} className={classNames('text-sm px-1 py-1 hover:bg-blue-300 active:bg-blue-500 rounded-sm text-white hover:no-underline', {
                                [activeLinkClass]: location.pathname === link.href
                            })}>
                                {link.label}
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
