
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlices';
import usersReducer from './slice/usersSlice';
import musicSlice from './slice/musicSlice';
import artistSlice from './slice/artistSlice';
import visualSlice from './slice/visualSlice';
import whiteSlice from './slice/whiteSlice';
import releaseSlice from './slice/releaseSlice';
import channelSlice from './slice/channelSlice';
import reportSlice from './slice/reportSlice';
import mcnSlice from './slice/mcnSlice';
import channelReportSlice from './slice/channelReportSlice';
import dashSlice from './slice/dashSlice';
import notifiSlice from './slice/notifiSlice';
import syndicateSlice from './slice/syndicateSlice';
import addUserSlice from './slice/addUserSlice';
import exportSlice from './slice/exportSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        musics:musicSlice,
        artist:artistSlice,
        claims:visualSlice,
        whitelist:whiteSlice,
        release:releaseSlice,
        createChannel:channelSlice,
        reportAndPaymentVisual:reportSlice,
        mcn:mcnSlice,
        channelReport:channelReportSlice,
        dashboard:dashSlice,
        notifications:notifiSlice,
        syndicate:syndicateSlice,
        user:addUserSlice,
        export:exportSlice
      
    },
});

export default store;
