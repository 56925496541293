import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { login, logout } from '../store/slice/authSlices';
import CustomAlert from '../components/ui/CustomAlert';
import { useAuth } from '../context/AuthContext';
import Preloader from '../components/ui/Preloader';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertIcon, setAlertIcon] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showPreloader, setShowPreloader] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loginAdm } = useAuth();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPreloader(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ email, password }));
    };

    useEffect(() => {
        if (auth.status === 'succeeded') {
            setAlertIcon('success');
            setAlertTitle('Thank You');
            setAlertMessage('You have successfully logged in.');
            setShowAlert(true);
        } else if (auth.status === 'failed') {
            setAlertIcon('error');
            setAlertTitle('Login Failed');
            setAlertMessage(auth.error || 'An error occurred. Please try again.');
            setShowAlert(true);
        }
    }, [auth.status]);

    const handleAlertClose = () => {
        if (auth.status === 'succeeded') {
            loginAdm();
            navigate('/dashboard');
        }
        setShowAlert(false);
    };

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            dispatch(logout());
            navigate('/');
        }
    }, [dispatch, navigate]);

    return (
        <div className="w-screen h-screen lg:h-auto py-24 flex justify-center items-center bg-black">
            {showPreloader ? (
                <Preloader />
            ) : (
                <div className="w-[80%] h-[90%] lg:h-[80%] bg-white flex justify-between">
                    <div className="w-full lg:w-1/2 md:w-[70%] p-16">
                        <Form onSubmit={handleSubmit}>
                            <h1 className="text-3xl font-bold text-center mb-10">Log in Admin Account</h1>
                            {auth.status === 'failed'}
                            <div className="input-container">
                                <Form.Control
                                    type="text"
                                    placeholder=" "
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full border-2 border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <Form.Label className="form-label">Email address</Form.Label>
                            </div>
                            <div className="input-container mt-6">
                                <Form.Control
                                    type="password"
                                    placeholder=" "
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full border-2 border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <Form.Label className="form-label">Password</Form.Label>
                            </div>
                            <Form.Text className="text-muted">
                                By continuing, you agree to the updated Terms of Condition, Terms of Service, and Privacy Policy.
                            </Form.Text>
                            <Button className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 w-full mt-10" type="submit" disabled={auth.status === 'loading'}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                    <div className="w-[0] lg:w-1/2 md:w-[30%]">
                        <img className="h-full w-full" src="./assets/adminbg.png" alt="" />
                    </div>
                </div>
            )}
            <CustomAlert show={showAlert} icon={alertIcon} title={alertTitle} message={alertMessage} onClose={handleAlertClose} />
        </div>
    );
};

export default AdminLogin;
