import React from 'react';
const backgroundImage = require('../images/Backround.jpg');

const Termservice = () => {
  return (
    <>
      <div
        className='w-full bg-cover bg-center p-10 lg:p-16 md:p-10 flex flex-col gap-4 items-center justify-center border border-black'
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <h1 className='text-2xl font-bold text-center mb-6'>Terms of Service Agreement</h1>
        
        <section className='w-full max-w-4xl text-left leading-6 text-md'>
          <h2 className='text-xl font-semibold mb-2'>A. <u>Digital Distribution Terms of Service:</u></h2>
          <p className='mb-4'>
            1. <strong><u>Grant of Rights:</u></strong> The Client hereby grants Brandroute Media the exclusive global rights to:
            <ul className='list-disc ml-6 mt-2'>
              <li>Serve as the exclusive licensor and digital distributor of the Client's Content to Resellers worldwide.</li>
              <li>Function as the digital asset manager for the Client's Content on platforms like YouTube, managing digital rights, content ID, fingerprinting, and monetization worldwide.</li>
              <li>Reproduce and transform the Client's Content into digitally encoded files, assign ISRC codes, and manage the collection, administration, and distribution of revenues derived from the Resellers' utilization of the Client's Content.</li>
            </ul>
          </p>
          <p className='mb-4'>
            2. <strong><u>Client Responsibilities:</u></strong>
            <ul className='list-disc ml-6 mt-2'>
              <li>Secure all necessary rights for Brandroute Media to legally reproduce and distribute the Content.</li>
              <li>Deliver content to Brandroute Media with all relevant details (e.g., composition identification, album titles, artist(s), catalogue and track numbers, explicit lyric indicators).</li>
            </ul>
          </p>
          <p className='mb-4'>
            3. <strong><u>Company Responsibilities:</u></strong>
            <ul className='list-disc ml-6 mt-2'>
              <li>Deploy commercially reasonable efforts to promote and market the Client's Content across suitable digital platforms and services.</li>
            </ul>
          </p>
          <p className='mb-4'>
            4. <strong><u>Compensation:</u></strong>
            <ul className='list-disc ml-6 mt-2'>
              <li>For music distribution and YouTube Content ID Creation, Brandroute Media will allocate 80% of gross revenue to the Client and retain 20% as service fees.</li>
            </ul>
          </p>

          <h2 className='text-xl font-semibold mb-2'>B. <u>Fingerprinting Terms and Service:</u></h2>
          <p className='mb-4'>
            <strong><u>Digital Rights Management:</u></strong> Brandroute Media will manage digital rights, content ID, fingerprinting, and monetization worldwide for the Client's Content on platforms like YouTube.
          </p>
          <p className='mb-4'>
            <strong><u>Ownership Requirements for Content ID Eligibility:</u></strong> The Client must own or control 100% of the melodies, lyrics, composition, and audio master to be eligible for Content ID.
          </p>
          <p className='mb-4'>
            <strong><u>Prohibited Manipulations and Viral Marketing Efforts:</u></strong> Any content artificially enhancing virality or creating multiple claims through paid services is strictly prohibited. The Client agrees not to engage in or submit content that manipulates claims or pays for content to be used as intros or outros on YouTube videos or any other content of any sources.
          </p>
          <p className='mb-4'>
            <strong><u>Restrictions on Remixes and Cover Songs:</u></strong> The submission of remixes or cover songs is prohibited unless the Client owns the original song outright or has secured a mechanical licence demonstrating such ownership.
          </p>
          <p className='mb-4'>
            <strong><u>Indemnification and Limitation of Liability:</u></strong> The Client agrees to indemnify, defend, and hold harmless Brandroute Media from any claims, liabilities, damages, and expenses arising from or related to any breach of the Client's warranties, representations, covenants, or obligations under this Agreement or from any infringement or alleged infringement of third-party intellectual property rights by the Content provided by the Client.
          </p>

          <h2 className='text-xl font-semibold mb-2'>C. <u>MCN Terms Of Service:</u></h2>
          <p className='mb-4'>
            1. <strong><u>MCN Services:</u></strong> Brandroute Media will distribute seventy percent (70%) of gross compensation to the Client as Master Proceeds and retain thirty percent (30%) as its Service Fee, reflecting the additional value and support provided through these services.
          </p>
          <p className='mb-4'>
            2. <strong><u>Created Channel:</u></strong> It is not possible to remove the owner access from channels that were created from CMS or MCN. This includes the email - zojakchannels@brandroutemedia.com, as well as any of our official email addresses. Payments will be held if the owner's access is removed.
          </p>
          <p className='mb-4'>
            3. <strong><u>Linked Channel:</u></strong> When we link your channel to our MCN, we need to put the manager access in our email (zojakchannels@brandroutemedia.com or any of our official emails). Payments will be held if the manager's access is removed.
          </p>
          <p className='mb-4'>
            4. <strong><u>Minimum Period:</u></strong> All channels that are created and linked must be kept on our network for a minimum of two years.
          </p>
          <p className='mb-4'>
            5. <strong><u>Content ID:</u></strong> Content ID cannot be enabled for your channel.
          </p>
          <p className='mb-4'>
            6. <strong><u>Copyright Strike:</u></strong> If the Client is unable to remove a copyright strike within 14 business days (excluding Saturdays, Sundays, and national holidays), payment will be held.
          </p>
          <p className='mb-4'>
            7. <strong><u>Cover & Remix Songs:</u></strong> We are not allowed to upload any cover or remix songs to CMS or MCN-connected channels. Payments will be held if this happens. However, covers and remixes are allowed if they meet the following conditions:
            <ul className='list-disc ml-6 mt-2'>
              <li>The remixes and covers must be works that have permission to use.</li>
              <li>They must be developed from original music that has recordings.</li>
              <li>They must have a mechanical licence.</li>
              <li>Copies of the permissions and licences must be attached to this agreement.</li>
            </ul>
          </p>

          <h2 className='text-xl font-semibold mb-2'>D. <u>Payment Schedule:</u></h2>
          <p className='mb-4'>
            <strong><u>Payment Terms:</u></strong> Payments to the Client will be made by the 20th of each English calendar month subject to the generation of proceeds, including applicable taxes.
          </p>
          <p className='mb-4'>
            <strong><u>Payment Method:</u></strong> Payments to the Client under this Agreement shall be made either through online remittance to the specified bank account. The Client is required to inform Brandroute Media in writing of any changes to the account details to ensure uninterrupted payment processing. Upon mutual written consent, both parties may agree to modify the payment method as necessary. Should any loss or penalty be incurred due to the Client's failure to notify Brandroute Media of banking information changes before any transaction, Brandroute Media reserves the right to deduct such amounts from subsequent payments owed to the Client.
          </p>
          <p className='mb-4'>
            <strong><u>Books and Records Management:</u></strong> Brandroute Media agrees to maintain precise and comprehensive records of all transactions and activities related to the Client under this Agreement. Should the Client request, Brandroute Media shall provide detailed reports of transactions for a retrospective period of up to six (6) months from the date of the request. The Client must actively manage, download, and archive these reports, as Brandroute Media's policy limits the storage of such monthly reports to six months. If a monthly royalty statement is not received, the Client must promptly notify Brandroute Media to ensure the timely resolution of such issues.
          </p>

          <h2 className='text-xl font-semibold mb-2'>E. <u>Ownership and Intellectual Property Rights:</u></h2>
          <p className='mb-4'>
            The Client affirms that all rights, titles, and interests, including but not limited to copyrights, trademarks, and any other intellectual property rights in and to the Content and any additional materials provided to Brandroute Media, remain the Client's exclusive property. Notwithstanding the preceding, the Client acknowledges that Brandroute Media will retain a non-exclusive license to use, reproduce, distribute, and display the Content and any additional materials as necessary to fulfill the services under this Agreement. Furthermore, the Client warrants that it possesses all requisite rights and licenses to grant the rights described in this Agreement.
          </p>

          <h2 className='text-xl font-semibold mb-2'>F. <u>Termination:</u></h2>
          <p className='mb-4'>
            <strong><u>Termination Conditions:</u></strong> Either party may terminate this Agreement at any time by giving thirty (30) days' written notice to the other party. Termination of this Agreement shall not affect the accrued rights and liabilities of either party arising before such termination.
          </p>
          <p className='mb-4'>
            <strong><u>Survival Clause:</u></strong> All clauses in this Agreement, which by their nature should survive termination, will remain in full force and effect, including but not limited to payment provisions, confidentiality obligations, indemnification, and limitations of liability.
          </p>
          <h2 className='text-xl font-semibold mb-2'>F. <u>Content Submission Restrictions:</u></h2>
          <p className='mb-4'>
            <strong><u>Prohibited Manipulations and Viral Marketing Efforts:</u></strong> The Client agrees not to engage in or submit content that manipulates claims or pays for content to be used as intros or outros on YouTube videos or any other content of any sources. The Client is accountable for all royalties related to the Content, ensuring the Company is protected against any claims, including reasonable attorney's fees arising from the Client's non-compliance.
          </p>

          <h2 className='text-xl font-semibold mb-2'>G. <u>GENERAL:</u></h2>
          <p className='mb-4'>
            <strong><u>Exclusion of Third Party Rights:</u></strong> Save to the extent expressly set out herein, this Agreement is not intended to nor shall it create any rights, entitlements, claims, or benefits enforceable by any person that is not a party hereto. Accordingly, save to the extent expressly set out in this Agreement, no person shall derive any benefit or have any right, entitlement, or claim in relation to this Agreement by virtue of the Contracts (Rights of Third Parties) Act 1999.
          </p>
          <p className='mb-4'>
            <strong><u>Relationship of the Parties:</u></strong> This Agreement does not create any form of partnership, joint venture, agency, or employment relationship between the parties. No party is authorised to make commitments on behalf of or represent the other party in any capacity.
          </p>
          <p className='mb-4'>
            <strong><u>Execution and Delivery:</u></strong> The Agreement can be executed digitally, via email as PDFs, and in multiple counterparts, each considered an original. Collectively, these counterparts constitute the same agreement as if signed by all parties in a single document.
          </p>
          <p className='mb-4'>
            <strong><u>Governing Law and Jurisdiction:</u></strong> All disputes arising from this Agreement shall be governed by the laws of the State of West Bengal. In the event of any dispute arising under this Agreement, the Client and Brandroute Media agree that such disputes will be adjudicated exclusively in the courts of Kolkata, West Bengal, India.
          </p>
          <p className='mb-4'>
            <strong><u>Legal Expenses:</u></strong> In any legal action related to this Agreement, the prevailing party has the right to recover reasonable legal fees and court costs.
          </p>
          <p className='mb-4'>
            <strong><u>Entire Agreement:</u></strong> This Agreement constitutes the entire agreement between the parties and supersedes all prior agreements.
          </p>
          <p className='mb-4'>
            <strong><u>Rights Granted:</u></strong> The Client grants Brandroute Media a non-exclusive right to handle digital rights, including addressing piracy and copyright infringement issues, with the content provided to digital service providers in accordance with the provisions outlined in this Agreement.
          </p>

          <p className='mb-4'>
            <strong><u>Signatures:</u></strong> This Agreement is executed as of the Effective Date mentioned above by the parties, who, by their signatures below, signify their agreement to all terms and conditions specified herein.
          </p>
        </section>
      </div>
    </>
  );
}

export default Termservice;
