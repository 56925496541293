import React from "react";
import { TbLoader } from "react-icons/tb";

const Loader = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <div className="flex items-center justify-center">
        <TbLoader className="animate-spin h-20 w-20 text-blue-500" />
       
      </div>
    </div>
  );
};

export default Loader;
