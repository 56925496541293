import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/users`, {
        headers: {
            Authorization: JSON.parse(localStorage.getItem('token'))
        }
    });
    console.log('fetchUsers response:', response.data.data.allUserdata);
    return response.data.data.allUserdata;
});

export const fetchPendingUsers = createAsyncThunk('users/fetchPendingUsers', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/users/pending`, {
        headers: {
            Authorization: JSON.parse(localStorage.getItem('token'))
        }
    });
    console.log('fetchPendingUsers response:', response.data.data.allUserdata);
    return response.data.data.allUserdata;
});

export const fetchRejectedUsers = createAsyncThunk('users/fetchRejectedUsers', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/users/rejected`, {
        headers: {
            Authorization: JSON.parse(localStorage.getItem('token'))
        }
    });
    console.log('fetchRejectedUsers response:', response.data.data.allUserdata);
    return response.data.data.allUserdata;
});

export const fetchApprovedUsers = createAsyncThunk('users/fetchApprovedUsers', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/users/approved`, {
        headers: {
            Authorization: JSON.parse(localStorage.getItem('token'))
        }
    });

    return response.data.data.allApprovedUsers;
});

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        allUserdata: [],
        pendingUsers: [],
        rejectedUsers: [],
        approvedUsers: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.allUserdata = action.payload;
                console.log('fetchUsers fulfilled:', state.allUserdata);
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchPendingUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPendingUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.pendingUsers = action.payload;
                console.log('fetchPendingUsers fulfilled:', state.pendingUsers);
            })
            .addCase(fetchPendingUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchRejectedUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRejectedUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.rejectedUsers = action.payload;
                console.log('fetchRejectedUsers fulfilled:', state.rejectedUsers);
            })
            .addCase(fetchRejectedUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchApprovedUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchApprovedUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.approvedUsers = action.payload;
                
            })
            .addCase(fetchApprovedUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default usersSlice.reducer;
