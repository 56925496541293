import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchMusicDistributions = createAsyncThunk('musics/fetchMusicDistributions', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/getAllClaimRequestByadmin`, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))
    }
  });
  return response.data.data;
});


export const approveRequest = createAsyncThunk('musics/approveRequest', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/ClaimRequestApprove/${id}`, {}, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))
    }
  });
  return response.data;
});


export const rejectRequest = createAsyncThunk('musics/rejectRequest', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/ClaimRequestReject/${id}`, {}, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))
    }
  });
  return response.data;
});

const musicsSlice = createSlice({
  name: 'musics',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMusicDistributions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMusicDistributions.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchMusicDistributions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(approveRequest.fulfilled, (state, action) => {
        const index = state.data.findIndex(item => item._id === action.meta.arg);
        if (index !== -1) {
          state.data[index].status = 'Approved';
        }
      })
      .addCase(rejectRequest.fulfilled, (state, action) => {
        const index = state.data.findIndex(item => item._id === action.meta.arg);
        if (index !== -1) {
          state.data[index].status = 'Rejected';
        }
      });
  },
});

export default musicsSlice.reducer;
