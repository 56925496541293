import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchDashboardStats = createAsyncThunk(
  'dashboard/fetchStats',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/dashboard/stats`,
      {
        headers: {
            Authorization: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    }
    );
    return response.data;
  }
);

const dashSlice = createSlice({
  name: 'dashboard',
  initialState: {
    totalClients: 0,
    totalActiveAssets: 0,
    totalVisualChannels: 0,
    totalMcnChannels: 0,
    percentageChanges: {
      totalClients: 0,
      totalActiveAssets: 0,
      totalVisualChannels: 0,
      totalMcnChannels: 0,
    },
    status: 'idle', 
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardStats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDashboardStats.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const data = action.payload.data;
        state.totalClients = data.totalClients;
        state.totalActiveAssets = data.totalActiveAssets;
        state.totalVisualChannels = data.totalVisualChannels;
        state.totalMcnChannels = data.totalMcnChannels;
        state.percentageChanges = data.percentageChanges;
      })
      .addCase(fetchDashboardStats.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default dashSlice.reducer;
