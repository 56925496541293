import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../components/ui/Modal'
import { createMCNReportAndPayment, searchMCNChannels } from '../store/slice/mcnSlice'
import Selection from '../components/ui/Selection'
import CustomAlert from '../components/ui/CustomAlert'; 

export default function AddMcnPaymentIDwise({ userId }) {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({
        clientID: '',
        clientName: '',
        reportMonth: '',
        date: new Date().toISOString().split('T')[0],
        CMS: '',
        channelName: '',
        revenue: '',
        comments: '',
        selectedChannelValue: ''
    })
    const [channelOptions, setChannelOptions] = useState([])
    const [cmsOptions, setCmsOptions] = useState([])
    const [addedChannels, setAddedChannels] = useState([])
    const [showAlert, setShowAlert] = useState(false); 
    const [alertContent, setAlertContent] = useState({}); 

    const dispatch = useDispatch()
    const { searchData } = useSelector((state) => state.mcn)

    useEffect(() => {
        if (searchData.length > 0) {
            const userData = searchData[0].user
            const channelNames = searchData[0].channelNames.map((channel) => ({
                value: channel.id,
                label: channel.name
            }))
            const cmsNames = searchData[0].cmsNames.map((cms) => ({
                name: cms.name,
                channelId: cms.id
            }))

            setFormData({
                clientID: userData.clientID,
                clientName: userData.fullName,
                reportMonth: '',
                date: new Date().toISOString().split('T')[0],
                CMS: cmsNames.length > 0 ? cmsNames[0].name : '',
                channelName: '',
                revenue: '',
                comments: '',
                selectedChannelValue: ''
            })

            setChannelOptions(channelNames)
            setCmsOptions(cmsNames)
        }
    }, [searchData])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectChange = (value) => {
        const selectedCMS = cmsOptions.find((cms) => cms.channelId === value)
        const selectedChannel = channelOptions.find((channel) => channel.value === value)

        setFormData({
            ...formData,
            CMS: selectedCMS ? selectedCMS.name : '',
            channelName: selectedChannel ? selectedChannel.label : '',
            selectedChannelValue: value
        })
    }

    const handleAddPayment = () => {
        const newPayment = {
            channelName: formData.channelName,
            CMS: formData.CMS,
            revenue: formData.revenue,
            reportMonth: formData.reportMonth,
            comments: formData.comments
        }
        setAddedChannels([...addedChannels, newPayment])
        setFormData({
            ...formData,
            channelName: '',
            CMS: '',
            revenue: '',
            reportMonth:'',
            comments: '',
            selectedChannelValue: ''
        })
    }

    const handleSubmit = () => {
        const dataToSubmit = {
            channels: addedChannels.map(({ reportMonth, comments, channelName, CMS, revenue }) => ({
                clientID: formData.clientID,
                clientName: formData.clientName,
                channelName: channelName,
                reportMonth: reportMonth,
                date: formData.date,
                CMS: CMS,
                revenue: parseFloat(revenue),
                comments: comments,
                user_id: searchData.length > 0 ? searchData[0].user._id : ''
            }))
        }

        dispatch(createMCNReportAndPayment(dataToSubmit)).unwrap()
        .then((response) => {
            if (response.status === "success") { 
                setOpen(false);
                setShowAlert(true);  
                setAlertContent({
                    icon: 'success',
                    title: 'Thank You',
                    message: 'MCN Channel Payment Records Created Successfully',
                });
                resetForm();
            } else {
                setShowAlert(true);  
                setAlertContent({
                    icon: 'error',
                    title: 'Failed',
                    message: "Failed to Create Visual Report!",
                });
                resetForm();
            }
        })
        .catch((error) => {
            setShowAlert(true);  
            setAlertContent({
                icon: 'error',
                title: 'Error',
                message: 'Failed to Create Visual Report!',
            });
            resetForm();
        });
};

    const handleSearch = (searchTerm) => {
        dispatch(searchMCNChannels(searchTerm))
    }

    const resetForm = () => {
        setFormData({
            clientID: '',
            clientName: '',
            reportMonth: '',
            date: new Date().toISOString().split('T')[0],
            channelName: '',
            revenue: '',
            CMS: '',
            comments: '',
            selectedChannelValue: ''
        });
        setAddedChannels([])
    };

    useEffect(() => {
        if (open && userId) {
            handleSearch(userId)
        }
    }, [open, userId])

    return (
        <main className="App">
            <p className="hover:bg-black hover:text-white p-1" onClick={() => setOpen(true)}>
                MCN Report
            </p>

            <Modal open={open} onClose={() => { setOpen(false); resetForm(); }}>
                <div className="rounded-md bg-black border-green-500 p-4">
                    <div className="modal-body w-[60vw] lg:w-[30vw] pt-4 h-auto flex flex-col gap-2">
                        <div className="text-white grid grid-cols-2 gap-2">
                            <label htmlFor="clientName" className="flex flex-col gap-2">
                                Client ID
                                <input
                                    type="text"
                                    id="clientID"
                                    name="clientID"
                                    value={formData.clientID}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Client ID"
                                />
                            </label>
                            <label htmlFor="clientName" className="flex flex-col gap-2">
                                Client Name
                                <input
                                    type="text"
                                    id="clientName"
                                    name="clientName"
                                    value={formData.clientName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Client Name"
                                />
                            </label>
                        </div>
                        <div className="grid grid-cols-3 gap-2 text-white">
                            <label htmlFor="reportMonth" className="flex flex-col gap-2">
                                Reporting Month
                                <input
                                    type="month"
                                    id="reportMonth"
                                    name="reportMonth"
                                    value={formData.reportMonth}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Reporting Month"
                                />
                            </label>
                            <label htmlFor="date" className="flex flex-col gap-2">
                                Date
                                <input
                                    type="text"
                                    id="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Date"
                                />
                            </label>
                            <label htmlFor="channelName" className="flex flex-col gap-2">
                                Channel Name
                                <Selection
                                    options={channelOptions}
                                    selectedOption={formData.selectedChannelValue}
                                    onSelect={(selectedValue) => handleSelectChange(selectedValue)}
                                />
                            </label>
                        </div>
                        
                        <div className="grid grid-cols-3 gap-2 text-white">
                            <label htmlFor="CMS" className="flex flex-col gap-2">
                                CMS
                                <input
                                    type="text"
                                    id="CMS"
                                    name="CMS"
                                    value={formData.CMS}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="CMS"
                                />
                            </label>
                            <label htmlFor="revenue" className="flex flex-col gap-2">
                                Revenue
                                <input
                                    type="text"
                                    id="revenue"
                                    name="revenue"
                                    value={formData.revenue}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Revenue"
                                />
                            </label>
                            <label htmlFor="comments" className="flex flex-col gap-2">
                                Comments
                                <input
                                    type="text"
                                    id="comments"
                                    name="comments"
                                    value={formData.comments}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Comments"
                                />
                            </label>
                        </div>

                        <button
                            type="button"
                            onClick={handleAddPayment}
                            className="bg-gray-400 p-1 font-medium text-white rounded-md"
                        >
                            + Add payment
                        </button>

                        <div className="overflow-y-auto max-h-20 mt-4">
                            <table className="border-collapse w-full text-white border border-white">
                                <thead>
                                    <tr>
                                        <th className="p-1 bg-black">Channel Name</th>
                                        <th className="p-1 bg-black">CMS</th>
                                        <th className="p-1 bg-black">Revenue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedChannels.map((channel, index) => (
                                        <tr key={index}>
                                            <td>{channel.channelName}</td>
                                            <td>{channel.CMS}</td>
                                            <td>{channel.revenue}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <button type="button" onClick={handleSubmit} className="btn btn-primary p-2 rounded-md">
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
            {/* Custom alert component */}
            <CustomAlert
                show={showAlert}
                icon={alertContent.icon}
                title={alertContent.title}
                message={alertContent.message}
                onClose={() => setShowAlert(false)}
            />
        </main>
    )
}
