import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const getAuthToken = () => JSON.parse(localStorage.getItem('token'));


export const fetchWhitelistEntries = createAsyncThunk(
  'whitelist/fetchWhitelistEntries',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/getAllWhitelist`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data.data;
  }
);


export const approveWhitelistEntry = createAsyncThunk(
  'whitelist/approveWhitelistEntry',
  async (id) => {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/StartWhitelist/approve/${id}`, {}, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  }
);

export const rejectWhitelistEntry = createAsyncThunk(
  'whitelist/rejectWhitelistEntry',
  async (id) => {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/StartWhitelist/reject/${id}`, {}, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  }
);

const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState: {
    data: [],
    loading: false,
    error: null,
    approveLoading: false,
    rejectLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhitelistEntries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWhitelistEntries.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchWhitelistEntries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(approveWhitelistEntry.pending, (state) => {
        state.approveLoading = true;
        state.error = null;
      })
      .addCase(approveWhitelistEntry.fulfilled, (state, action) => {
        state.approveLoading = false;
        const index = state.data.findIndex(item => item._id === action.meta.arg);
        if (index !== -1) {
          state.data[index].status = 'Approved';
        }
      })
      .addCase(approveWhitelistEntry.rejected, (state, action) => {
        state.approveLoading = false;
        state.error = action.error.message;
      })
      .addCase(rejectWhitelistEntry.pending, (state) => {
        state.rejectLoading = true;
        state.error = null;
      })
      .addCase(rejectWhitelistEntry.fulfilled, (state, action) => {
        state.rejectLoading = false;
        const index = state.data.findIndex(item => item._id === action.meta.arg);
        if (index !== -1) {
          state.data[index].status = 'Rejected';
        }
      })
      .addCase(rejectWhitelistEntry.rejected, (state, action) => {
        state.rejectLoading = false;
        state.error = action.error.message;
      });
  },
});

export default whitelistSlice.reducer;
