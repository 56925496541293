import React from 'react'

import Recent from '../components/Recent'
import AdminStatus from '../components/AdminStatus'

export default function Dashboard() {
    return (
        <div className="flex flex-col gap-4 ">
            <AdminStatus />

            <div className=" flex flex-col md:flex-col lg:flex-row gap-2 ">
                <Recent />
            </div>
        </div>
    )
}
