import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import backgroundImage from '../../images/Backround.jpg'


export default function Layout() {
  return (
    <div
      className="h-auto w-screen flex flex-row overflow-hidden"
  
    >
      <Sidebar />
      <div className="flex flex-col flex-1 bg-cover bg-center "
      style={{ backgroundImage: `url(${backgroundImage})`,
     }}
      > 
        <Header />
        <div className="flex-1 p-2 min-h-0 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}


