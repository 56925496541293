
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// REGISTER USERS
export const registerUser = createAsyncThunk('user/registerUser', async (formDataPayload, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/adminRegisterUser`, formDataPayload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
    }
});

// UPDATE USERS
export const updateUser = createAsyncThunk('user/updateUser', async ({ userId, formDataPayload }, thunkAPI) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/adminUpdateUser/${userId}`, formDataPayload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
    }
});

// TOGGLE CLIENT STATUS
export const toggleClientStatus = createAsyncThunk('user/toggleClientStatus', async ({ clientId, isActive }, thunkAPI) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/api/toggleClientStatus/${clientId}`, { isActive });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
    }
});

// FETCH USER BY ID
export const fetchUserById = createAsyncThunk('user/fetchUserById', async (userId, thunkAPI) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/user/approved/${userId}`);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
    }
});

const addUserSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        success: false,
        error: null,
        user: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(toggleClientStatus.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(toggleClientStatus.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(toggleClientStatus.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(fetchUserById.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.user = action.payload.data.user; 
                state.error = null;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            });
    }
});

export default addUserSlice.reducer;
