import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: [],
  searchData: [],
  loading: false,
  searchLoading: false,
  error: null,
  searchError: null,
};

export const createVisualReportAndPayment = createAsyncThunk(
  'reportAndPaymentVisual/createVisualReportAndPayment',
  async (formData) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/create/ReportAndPaymentVisualClaim`, formData, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/json',
      },
    });
    return response.data; 
  }
);

export const getAllVisualReportsAndPayments = createAsyncThunk(
  'reportAndPaymentVisual/getAllVisualReportsAndPayments',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/api/get/ReportAndPaymentVisualClaims`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
    });
    return response.data.data;
  }
);

export const searchVisualChannels = createAsyncThunk(
  'reportAndPaymentVisual/searchVisualChannels',
  async (searchTerm) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/VisualChannelSearch?searchVisualChannel=${searchTerm}`, { searchVisualChannel: searchTerm }, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/json',
      },
    });
    return response.data.data;
  }
);

export const updateApprovalStatus = createAsyncThunk(
  'reportAndPaymentVisual/updateApprovalStatus',
  async ({ id }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/api/${id}/status`,
        {},
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data; 
    } catch (error) {
      throw new Error(error.response.data.message || error.message);
    }
  }
);

export const deleteReportAndPaymentVisualClaim = createAsyncThunk(
  'reportAndPaymentVisual/deleteReportAndPaymentVisualClaim',
  async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/api/reportAndPaymentVisualClaim/${id}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token')),
        },
      });
      return id; 
    } catch (error) {
      throw new Error(error.response.data.message || error.message);
    }
  }
);

export const deleteReportAndPaymentMCNChannel = createAsyncThunk(
  'reportAndPaymentVisual/deleteReportAndPaymentMCNChannel',
  async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/api/reportAndPaymentMCNChannel/${id}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token')),
        },
      });
      return id; 
    } catch (error) {
      throw new Error(error.response.data.message || error.message);
    }
  }
);

const reportSlice = createSlice({
  name: 'reportAndPaymentVisual',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createVisualReportAndPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createVisualReportAndPayment.fulfilled, (state, action) => {
        state.data.push(action.payload); 
        state.loading = false;
        state.error = null;
      })
      .addCase(createVisualReportAndPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllVisualReportsAndPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllVisualReportsAndPayments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllVisualReportsAndPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchVisualChannels.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
      })
      .addCase(searchVisualChannels.fulfilled, (state, action) => {
        state.searchData = action.payload;
        state.searchLoading = false;
        state.searchError = null;
      })
      .addCase(searchVisualChannels.rejected, (state, action) => {
        state.searchLoading = false;
        state.searchError = action.error.message;
      })
      .addCase(updateApprovalStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateApprovalStatus.fulfilled, (state, action) => {
        state.approvalStatus = action.payload; 
        state.loading = false;
        state.error = null;
      })
      .addCase(updateApprovalStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteReportAndPaymentVisualClaim.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteReportAndPaymentVisualClaim.fulfilled, (state, action) => {
        state.data = state.data.filter(item => item._id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteReportAndPaymentVisualClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteReportAndPaymentMCNChannel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteReportAndPaymentMCNChannel.fulfilled, (state, action) => {
        state.data = state.data.filter(item => item._id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteReportAndPaymentMCNChannel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default reportSlice.reducer;
