import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../components/ui/Modal'
import CustomAlert from '../components/ui/CustomAlert'
import { registerUser } from '../store/slice/addUserSlice'
import backgroundImage from '../images/bgs.jpg'

export default function AddUserAdmin({ onAddSuccess }) {
    const [open, setOpen] = useState(false)
    const [govIdFile, setGovIdFile] = useState(null)
    const [govAddrFile, setGovAddrFile] = useState(null)
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        region: '',
        describesYou: '',
        termsAccepted: false,
        legalName: '',
        labelName: '',
        governmentId: '',
        governmentAddress: '',
        digitalSignature: null,
        mobileNumber: '',
        password: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        accountHolderName: ''
    })
    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState({})
    const [digitalSignaturePreview, setDigitalSignaturePreview] = useState(null)

    const dispatch = useDispatch()
    const { loading, success, error } = useSelector((state) => state.user)

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target
        const inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value
        setFormData({
            ...formData,
            [name]: inputValue
        })

        if (type === 'file' && name === 'digitalSignature') {
            setDigitalSignaturePreview(URL.createObjectURL(files[0]))
        }
    }

    const handleFileChange = (e, setter) => {
        setter(e.target.files[0])
    }

    const validateForm = () => {
        const requiredFields = [
            'fullName',
            'email',
            'describesYou',
            'legalName',
            'labelName',
            'mobileNumber',
            'password',
            'region',
            'termsAccepted'
        ]
        for (let field of requiredFields) {
            if (!formData[field]) {
                setAlertContent({
                    icon: 'error',
                    title: 'Validation Error',
                    message: `Please fill the ${field.replace(/([A-Z])/g, ' $1')} field.`
                })
                setShowAlert(true)
                return false
            }
        }
        if (!govIdFile || !govAddrFile) {
            setAlertContent({
                icon: 'error',
                title: 'Validation Error',
                message: `Please upload required government documents.`
            })
            setShowAlert(true)
            return false
        }
        return true
    }

    const handleSubmit = () => {
        if (!validateForm()) return

        const data = new FormData()
        Object.keys(formData).forEach((key) => {
            data.append(key, formData[key])
        })
        if (govIdFile) data.append('governmentId', govIdFile)
        if (govAddrFile) data.append('governmentAddress', govAddrFile)

        dispatch(registerUser(data))
            .unwrap()
            .then(() => {
                setAlertContent({
                    icon: 'success',
                    title: 'Success',
                    message: 'User registered by Admin successfully!'
                })
                setShowAlert(true)
                setOpen(false)

                // Clear the form after successful registration
                setGovIdFile(null)
                setGovAddrFile(null)
                setDigitalSignaturePreview(null)
                setFormData({
                    fullName: '',
                    email: '',
                    region: '',
                    describesYou: '',
                    termsAccepted: false,
                    legalName: '',
                    labelName: '',
                    governmentId: '',
                    governmentAddress: '',
                    digitalSignature: null,
                    mobileNumber: '',
                    password: '',
                    bankName: '',
                    accountNumber: '',
                    ifscCode: '',
                    accountHolderName: ''
                })

                // Use a key to force re-render of the component
                onAddSuccess()
            })

            .catch(() => {
                setAlertContent({
                    icon: 'error',
                    title: 'Error',
                    message: 'Failed to register user.'
                })
                setShowAlert(true)
            })
    }

    const options = [
        { value: 'Artist', label: 'Artist' },
        { value: 'Producer', label: 'Producer' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Record Label', label: 'Record Label' },
        { value: 'Other', label: 'Other' }
    ]

    return (
        <main className="App">
            <p
                className="transition ease-in-out hover:-translate-y-1 hover:scale-110 bg-blue-500 p-2 text-white rounded-md cursor-pointer"
                onClick={() => setOpen(true)}
            >
                Add User
            </p>

            <Modal key={formData.describesYou} open={open} onClose={() => setOpen(false)}>
                <div
                    className="rounded-md border-green-500 p-4"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="modal-body w-[60vw] lg:w-[50vw] px-4 h-auto flex flex-col gap-2 overflow-y-auto max-h-[70vh] mt-6">
                        <div className="text-white grid grid-cols-2 gap-2">
                            <label className="flex flex-col gap-2">
                                <div>
                                    {' '}
                                    Full Name <span className="text-rose-600 font-bold">*</span>{' '}
                                </div>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Full Name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    {' '}
                                    Email <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Email"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    Describe You <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <select
                                    name="describesYou"
                                    value={formData.describesYou}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                >
                                    <option className="text-white bg-black" value="">
                                        Select Your Role
                                    </option>
                                    {options.map((option) => (
                                        <option className="text-white bg-black" key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    Legal Name <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <input
                                    type="text"
                                    name="legalName"
                                    value={formData.legalName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Legal Name"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    Label Name <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <input
                                    type="text"
                                    name="labelName"
                                    value={formData.labelName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Label Name"
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                <div>
                                    Mobile Number <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <input
                                    type="text"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Mobile Number"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    Password <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <input
                                    type="text"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Password"
                                />
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    {' '}
                                    Region <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <input
                                    type="text"
                                    name="region"
                                    value={formData.region}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Select Your Region"
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                Account Holder
                                <input
                                    type="text"
                                    name="accountHolderName"
                                    value={formData.accountHolderName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Holder name"
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                Bank Name
                                <input
                                    type="text"
                                    name="bankName"
                                    value={formData.bankName}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Bank name"
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                Account No.
                                <input
                                    type="text"
                                    name="accountNumber"
                                    value={formData.accountNumber}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Account number"
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                IFSC/SWIFT Code
                                <input
                                    type="text"
                                    name="ifscCode"
                                    value={formData.ifscCode}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                    placeholder="Enter IFSC no."
                                />
                            </label>

                            <label className="flex flex-col gap-2">
                                <div>
                                    Digital Signature{' '}
                                    <span className="text-rose-600 font-sm">* (174x61)(MAX 100KB)(.png)</span>
                                </div>
                                <input
                                    type="file"
                                    accept=".png"
                                    name="digitalSignature"
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                />
                            </label>
                            {digitalSignaturePreview && (
                                <div className="flex flex-col gap-2">
                                    <span>Digital Signature Preview:</span>
                                    <img
                                        src={digitalSignaturePreview}
                                        alt="Digital Signature Preview"
                                        className="border border-white rounded-md p-2"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="grid grid-cols-2 gap-4 text-white">
                            <label className="flex flex-col gap-2 ">
                                <div>
                                    Government ID Name <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <select
                                    name="governmentId"
                                    value={formData.governmentId}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                >
                                    <option className="text-white bg-black" value="">
                                        Select ID Type
                                    </option>
                                    <option className="text-white bg-black" value="Aadhar Card">
                                        Aadhar Card
                                    </option>
                                    <option className="text-white bg-black" value="Voter Card">
                                        Voter Card
                                    </option>
                                    <option className="text-white bg-black" value="Driving License">
                                        Driving License
                                    </option>
                                    <option className="text-white bg-black" value="Pan">
                                        Pan
                                    </option>
                                    <option className="text-white bg-black" value="Citizen Identity">
                                        Citizen Identity
                                    </option>
                                    <option className="text-white bg-black" value="Others">
                                        Others
                                    </option>
                                </select>
                            </label>
                            <label className="flex flex-col gap-2 mt-2">
                                <div>
                                    Government ID <span className="text-rose-600 font-sm">*(MAX SIZE 500KB)</span>
                                </div>
                                <input type="file" onChange={(e) => handleFileChange(e, setGovIdFile)} />
                            </label>
                            <label className="flex flex-col gap-2">
                                <div>
                                    Government Address Name <span className="text-rose-600 font-bold">*</span>
                                </div>
                                <select
                                    name="governmentAddress"
                                    value={formData.governmentAddress}
                                    onChange={handleInputChange}
                                    className="border border-white rounded-md p-2 bg-transparent"
                                >
                                    <option className="text-white bg-black" value="">
                                        Select Address Type
                                    </option>
                                    <option className="text-white bg-black" value="Aadhar Card">
                                        Aadhar Card
                                    </option>
                                    <option className="text-white bg-black" value="Voter Card">
                                        Voter Card
                                    </option>
                                    <option className="text-white bg-black" value="Driving License">
                                        Driving License
                                    </option>

                                    <option className="text-white bg-black" value="Citizen Identity">
                                        Citizen Identity
                                    </option>
                                    <option className="text-white bg-black" value="Others">
                                        Others
                                    </option>
                                </select>
                            </label>
                            <label className="flex flex-col gap-2 mt-2">
                                <div>
                                    Government Address <span className="text-rose-600 font-sm">*(MAX SIZE 500KB)</span>
                                </div>
                                <input type="file" onChange={(e) => handleFileChange(e, setGovAddrFile)} />
                            </label>
                        </div>
                    </div>

                    <label className="flex items-center gap-2 text-white mt-2">
                        <input
                            type="checkbox"
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleInputChange}
                            className="border border-white rounded-md p-2 bg-transparent"
                        />
                        <span>
                            Accept Terms and Conditions <span className="text-rose-600 font-bold">*</span>
                        </span>
                    </label>
                    <div className="modal-footer flex gap-4 mt-4">
                        <button
                            className="transition ease-in-out hover:-translate-y-1 hover:scale-110 bg-red-600 p-2 text-white rounded-md"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="transition ease-in-out hover:-translate-y-1 hover:scale-110 bg-green-600 p-2 text-white rounded-md"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Registering...' : 'Register'}
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Custom alert component */}
            <CustomAlert
                show={showAlert}
                icon={alertContent.icon}
                title={alertContent.title}
                message={alertContent.message}
                onClose={() => setShowAlert(false)}
            />
        </main>
    )
}
